import React, { useEffect, useState } from "react";
import NewsFullPage from "../components/commons/NewsFullPage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";

const NewsItem = ({ news }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <NewsFullPage news={news} isMobile={isMobile} />
            <Footer />
            <SiteMap />
        </div>
    );
};

export default NewsItem;
