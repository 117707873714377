const BlackCalculator = () => {
    return (
        <svg
        width="40"
        height="40"
            viewBox="0 0 35 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.4286 2.85714V37.1429H2.85714V2.85714H31.4286ZM31.4286 0H2.85714C2.09938 0 1.37266 0.301019 0.836838 0.836838C0.301019 1.37266 0 2.09938 0 2.85714V37.1429C0 37.9006 0.301019 38.6273 0.836838 39.1632C1.37266 39.699 2.09938 40 2.85714 40H31.4286C32.1863 40 32.9131 39.699 33.4489 39.1632C33.9847 38.6273 34.2857 37.9006 34.2857 37.1429V2.85714C34.2857 2.09938 33.9847 1.37266 33.4489 0.836838C32.9131 0.301019 32.1863 0 31.4286 0Z"
                fill="#202020"
            />
            <path
                d="M7.14282 30.0002H9.99997V32.8574H7.14282V30.0002ZM24.2857 30.0002H27.1428V32.8574H24.2857V30.0002ZM7.14282 22.8574H9.99997V25.7145H7.14282V22.8574ZM24.2857 22.8574H27.1428V25.7145H24.2857V22.8574ZM7.14282 15.7145H9.99997V18.5716H7.14282V15.7145ZM15.7143 30.0002H18.5714V32.8574H15.7143V30.0002ZM15.7143 22.8574H18.5714V25.7145H15.7143V22.8574ZM15.7143 15.7145H18.5714V18.5716H15.7143V15.7145ZM24.2857 15.7145H27.1428V18.5716H24.2857V15.7145ZM7.14282 7.14307H27.1428V11.4288H7.14282V7.14307Z"
                fill="#202020"
            />
        </svg>
    );
};

export default BlackCalculator;
