import { Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";

function Organize({ isMobile }) {
    return (
        <Fragment>
            <Grid container marginY={6} display={"flex"}>
                <Grid
                    xs={12}
                    md={12}
                    marginX={8}
                    marginTop={4}
                    color="#202020"
                    borderBottom={1}
                >
                    <Typography
                        color="#202020"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Sindicalize-se!
                    </Typography>
                </Grid>
                <Grid
                    xs={12}
                    md={11}
                    marginLeft={isMobile ? 0 : 8}
                    marginTop={2}
                    display={"flex"}
                >
                    <a
                        href="https://sindicalizacao.sindsep-sp.org.br"
                        target={"_blank"}
                        rel="noreferrer"
                        aria-label="redirecionamento para sindicalização online"
                        style={{
                            width: "100%",
                            textDecoration: "none",
                        }}
                    >
                        <img
                            src={
                                isMobile
                                    ? "../files/banner-mobile.png"
                                    : "../files/banner-pc.png"
                            }
                            width={"100%"}
                            alt="imagem de banner"
                        />
                    </a>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Organize;
