import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header3 from "../components/Header3";
import SiteMap from "../components/SiteMap";

import { Button, Grid, Typography } from "@mui/material";

const FinishAccount = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header3 isMobile={isMobile} />
            <Grid
                backgroundColor="white"
                borderRadius={2}
                paddingTop={8}
                paddingX={64}
                gap={2}
                rowGap={2}
                container
            >
                <Grid xs={12} md={11.12}>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="37.57px"
                    >
                        Obrigado por realizar seu cadastro
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12} mt={4}>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="20.8px"
                        whiteSpace={"pre-line"}
                    >
                        {`Os seu dados foram enviados corretamente. Você pode acompanhar o a situação do cadastro pela área do servidor. 
                        
                        Você também será avisado por SMS ou E-mail assim que aprovação estiver finalizada.`}
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12} mt={4} display={'flex'} justifyContent={"center"}>
                    <Button
                        style={{
                            borderRadius: 32,
                            marginTop: 32,
                            textAlign: "center",
                            backgroundColor: "#EB2227",
                        }}
                    >
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            padding={2}
                        >
                            VOLTAR PARA O SINDSEP
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Footer />
            <SiteMap  />
        </div>
    );
};

export default FinishAccount;
