import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function NewsItemLateral({
    date,
    image,
    title,
    description,
    link,
    categorie,
    categorieColor,
    isMobile
}) {
    function formatDate() {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    return (
        <Link
            aria-label="noticias"
            to={`/noticia?link=${link}`}
            rel="noreferrer"
            style={{
                textDecoration: "none",
            }}
        >
            <Box marginBottom={2} display="flex" flexDirection={isMobile ? "column" : "row"} gap={2}>
                <img
                    src={image}
                    width={"300px"}
                    height={"200px"}
                    alt="imagem de capa da noticia"
                    style={{
                        objectFit: "fill",
                        minWidth: "300px",
                    }}
                />
                <Box>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="14px"
                    >
                        {formatDate()}
                    </Typography>
                    <Box
                        style={{
                            width: "fit-content",
                            padding: "2px 12px",
                            backgroundColor: categorieColor,
                            borderRadius: 50,
                        }}
                        marginY={1}
                    >
                        <Typography
                            color="#010101"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="10px"
                            lineHeight="14px"
                            letterSpacing={"0.1em"}
                        >
                            {categorie}
                        </Typography>
                    </Box>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
        </Link>
    );
}

export default NewsItemLateral;
