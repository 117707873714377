import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function GeneralItem({
    date,
    image,
    description,
    link
}) {
    function formatDate() {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    return (
        <Link
            aria-label="noticias"
            to={link}
            rel="noreferrer"
            style={{
                textDecoration: "none",
            }}
        >
            <Box marginBottom={2} display="flex" flexDirection={"column"} gap={2}>
                <img
                    src={image}
                    width={'350px'}
                    height={'175px'}
                    alt="imagem de capa da galeria"
                    style={{
                        objectFit: "cover",
                        minWidth: "200px",
                        maxWidth: "100%"
                    }}
                />
                <Box>
                    
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="20px"
                    >
                        {description}
                    </Typography>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="12px"
                        lineHeight="14px"
                        marginTop={1}
                    >
                        {formatDate()}
                    </Typography>
                </Box>
            </Box>
        </Link>
    );
}

export default GeneralItem;
