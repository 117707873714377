import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

const PageId = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [page, setPage] = useState(null);
    const { id } = useParams();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadPage = async () => {
            const pageData = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/pages`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            pageData.data.map((item) => {
                if (item.link.includes(id)) setPage(item);
                return false;
            });
        };

        loadPage();

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, [id]);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            {page && (
                <Box
                    marginY={6}
                    gap={2}
                    display={"flex"}
                    paddingX={8}
                    marginBottom={16}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: page.ck,
                        }}
                    ></div>
                </Box>
            )}
            <Footer />
            <SiteMap />
        </div>
    );
};

export default PageId;
