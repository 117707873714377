import React, { useEffect } from "react";

const Minuta = () => {

    useEffect(() => {
        window.location.href = 'https://forms.office.com/r/9EgsQZmHXZ';
    }, []);

    return (
        <div>
        </div>
    );
};

export default Minuta;
