const Table = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 5C0 3.67392 0.526784 2.40215 1.46447 1.46447C2.40215 0.526784 3.67392 0 5 0L35 0C36.3261 0 37.5979 0.526784 38.5355 1.46447C39.4732 2.40215 40 3.67392 40 5V35C40 36.3261 39.4732 37.5979 38.5355 38.5355C37.5979 39.4732 36.3261 40 35 40H5C3.67392 40 2.40215 39.4732 1.46447 38.5355C0.526784 37.5979 0 36.3261 0 35V5ZM37.5 10H27.5V17.5H37.5V10ZM37.5 20H27.5V27.5H37.5V20ZM37.5 30H27.5V37.5H35C35.663 37.5 36.2989 37.2366 36.7678 36.7678C37.2366 36.2989 37.5 35.663 37.5 35V30ZM25 37.5V30H15V37.5H25ZM12.5 37.5V30H2.5V35C2.5 35.663 2.76339 36.2989 3.23223 36.7678C3.70107 37.2366 4.33696 37.5 5 37.5H12.5ZM2.5 27.5H12.5V20H2.5V27.5ZM2.5 17.5H12.5V10H2.5V17.5ZM15 10V17.5H25V10H15ZM25 20H15V27.5H25V20Z"
                fill="#202020"
            />
        </svg>
    );
};

export default Table;
