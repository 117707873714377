const Document = () => {
    return (
        <svg
            width="23"
            height="32"
            viewBox="0 0 23 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.5143 8.34286L14.5143 0.342857C14.2857 0.114286 14.0571 0 13.7143 0H2.28571C1.02857 0 0 1.02857 0 2.28571V29.7143C0 30.9714 1.02857 32 2.28571 32H20.5714C21.8286 32 22.8571 30.9714 22.8571 29.7143V9.14286C22.8571 8.8 22.7429 8.57143 22.5143 8.34286ZM13.7143 2.74286L20.1143 9.14286H13.7143V2.74286ZM20.5714 29.7143H2.28571V2.28571H11.4286V9.14286C11.4286 10.4 12.4571 11.4286 13.7143 11.4286H20.5714V29.7143Z"
                fill="#010101"
            />
            <path
                d="M4.57153 22.8571H18.2858V25.1429H4.57153V22.8571ZM4.57153 16H18.2858V18.2857H4.57153V16Z"
                fill="#010101"
            />
        </svg>
    );
};

export default Document;
