import React from "react";
import { Box, Button, Grid2, Typography } from "@mui/material";

function MaterialFullPage({ material }) {
    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    return (
        <Box marginBottom={2} p={4}>
            <Grid2
                container
                display={"flex"}
                mt={2}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid2 
                    xs={12}
                    md={12}
                    gap={2}
                    marginTop={6}
                    marginY={6}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="37.57px"
                    >
                        {material.titulo}
                    </Typography>
                    <Grid2 xs={12} md={12}>
                        <Typography
                            color="#010101"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="12px"
                            lineHeight="14px"
                        >
                            {formatDate(material.incData ? material.incData : material.data)}
                        </Typography>
                    </Grid2>
                    <Grid2 xs={12} md={12}>
                        <Typography
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="500"
                            fontSize="14px"
                            lineHeight="20px"
                            mt={1}
                            color="#010101"
                        >
                            {material.description}
                        </Typography>
                    </Grid2>
                    <img
                        src={
                            material.capa.includes("aws")
                                ? material.capa
                                : `https://materiais-bucket.s3.sa-east-1.amazonaws.com/${material.ID}/capa/${material.capa}`
                        }
                        width={"350px"}
                        alt="imagem de capa da noticia"
                        style={{
                            objectFit: "fill",
                            minWidth: "200px",
                        }}
                    />
                    <a
                        rel="noreferrer"
                        target={"_blank"}
                        aria-label={`redirecionamento para a materia ${material.titulo}`}
                        href={
                            material.arquivo.includes("aws")
                                ? material.arquivo
                                : `https://materiais-bucket.s3.sa-east-1.amazonaws.com/${material.ID}/arquivo/${material.arquivo}`
                        }
                    >
                        <Button
                            size="small"
                            color="warning"
                            variant="outlined"
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}
                        >
                            DOWNLOAD
                        </Button>
                    </a>
                </Grid2>
            </Grid2>
        </Box>
    );
}

export default MaterialFullPage;
