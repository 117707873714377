import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header3 from "../components/Header3";
import SiteMap from "../components/SiteMap";

import { Grid, Typography } from "@mui/material";
import PersonalData from "../components/PersonalData";
import ProfissionalData from "../components/ProfissionalData";
import AddressData from "../components/AddressData";

const SignUp = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [selected, setSelected] = useState(0);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header3 isMobile={isMobile} />
            <Grid
                backgroundColor="white"
                borderRadius={2}
                paddingTop={8}
                paddingX={48}
                gap={2}
                rowGap={2}
                container
            >
                <Grid xs={12} md={11.12}>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="24px"
                        lineHeight="28.18px"
                    >
                        Falta pouco
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12}>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="20.8px"
                    >
                        Preencha o formulário abaixo para continuar seu
                        cadastro.
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                backgroundColor="white"
                borderRadius={2}
                paddingTop={8}
                paddingX={48}
                gap={2}
                rowGap={2}
                container
            >
                <Grid xs={3.5} md={3.5}>
                    <div onClick={() => setSelected(0)} style={{ cursor: 'pointer'}}>
                        <Typography
                            color={selected === 0 ? "#EB2227" : "#B8BABE"}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21.13px"
                            paddingBottom={2}
                            marginRight={2}
                            borderBottom={4}
                        >
                            Dados pessoais
                        </Typography>
                    </div>
                </Grid>
                <Grid xs={3.5} md={3.5}>
                    <div onClick={() => setSelected(1)} style={{ cursor: 'pointer'}}>
                        <Typography
                            color={selected === 1 ? "#EB2227" : "#B8BABE"}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21.13px"
                            paddingBottom={2}
                            marginRight={2}
                            borderBottom={4}
                        >
                            Dados profissionais
                        </Typography>
                    </div>
                </Grid>
                <Grid xs={3.5} md={3.5}>
                    <div onClick={() => setSelected(2)} style={{ cursor: 'pointer'}}>
                        <Typography
                            color={selected === 2 ? "#EB2227" : "#B8BABE"}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="18px"
                            lineHeight="21.13px"
                            paddingBottom={2}
                            marginRight={2}
                            borderBottom={4}
                        >
                            Endereço
                        </Typography>
                    </div>
                </Grid>
            </Grid>
            {selected === 0 && <PersonalData />}
            {selected === 1 && <ProfissionalData />}
            {selected === 2 && <AddressData />}
            <Footer />
            <SiteMap  />
        </div>
    );
};

export default SignUp;
