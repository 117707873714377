const Whatsapp = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.00802676 40L2.71203 30.064C0.930302 27.0097 -0.00578126 23.536 2.68645e-05 20C2.68645e-05 8.95399 8.95402 0 20 0C31.046 0 40 8.95399 40 20C40 31.046 31.046 40 20 40C16.4656 40.0057 12.9933 39.0703 9.94002 37.29L0.00802676 40ZM12.782 10.616C12.5237 10.632 12.2714 10.7001 12.04 10.816C11.8231 10.9389 11.625 11.0924 11.452 11.272C11.212 11.498 11.076 11.694 10.93 11.884C10.1908 12.846 9.79324 14.0268 9.80002 15.24C9.80402 16.22 10.06 17.174 10.46 18.066C11.278 19.87 12.624 21.78 14.402 23.55C14.83 23.976 15.248 24.404 15.698 24.802C17.9048 26.7449 20.5345 28.1461 23.378 28.894L24.516 29.068C24.886 29.088 25.256 29.06 25.628 29.042C26.2105 29.0119 26.7792 28.8542 27.294 28.58C27.5559 28.4451 27.8115 28.2983 28.06 28.14C28.06 28.14 28.146 28.084 28.31 27.96C28.58 27.76 28.746 27.618 28.97 27.384C29.136 27.212 29.28 27.01 29.39 26.78C29.546 26.454 29.702 25.832 29.766 25.314C29.814 24.918 29.8 24.702 29.794 24.568C29.786 24.354 29.608 24.132 29.414 24.038L28.25 23.516C28.25 23.516 26.51 22.758 25.448 22.274C25.336 22.2252 25.216 22.1974 25.094 22.192C24.9572 22.1779 24.8189 22.1933 24.6885 22.2371C24.5581 22.281 24.4386 22.3522 24.338 22.446V22.442C24.328 22.442 24.194 22.556 22.748 24.308C22.665 24.4195 22.5507 24.5038 22.4196 24.5501C22.2885 24.5964 22.1466 24.6026 22.012 24.568C21.8817 24.5331 21.754 24.489 21.63 24.436C21.382 24.332 21.296 24.292 21.126 24.218L21.116 24.214C19.9718 23.7144 18.9125 23.0397 17.976 22.214C17.724 21.994 17.49 21.754 17.25 21.522C16.4632 20.7685 15.7774 19.916 15.21 18.986L15.092 18.796C15.0073 18.6683 14.9387 18.5306 14.888 18.386C14.812 18.092 15.01 17.856 15.01 17.856C15.01 17.856 15.496 17.324 15.722 17.036C15.9102 16.7966 16.0858 16.5476 16.248 16.29C16.484 15.91 16.558 15.52 16.434 15.218C15.874 13.85 15.294 12.488 14.698 11.136C14.58 10.868 14.23 10.676 13.912 10.638C13.804 10.626 13.696 10.614 13.588 10.606C13.3194 10.5926 13.0503 10.5953 12.782 10.614V10.616Z"
                fill="#50CD5D"
            />
        </svg>
    );
};

export default Whatsapp;
