import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SiteMap from "../components/SiteMap";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function EditData({ isEditing }) {
    const navigate = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);

    const [name, setName] = useState("");
    const [sex, setSex] = useState("");
    const [etnicity, setEtnicity] = useState("");
    const [birthday, setBirthday] = useState(new Date());

    const [disability, setDisability] = useState("Sim");
    const [disabilityType, setDisabilityType] = useState("");

    const [address, setAddress] = useState("");
    const [number, setNumber] = useState("");
    const [complement, setComplement] = useState("");
    const [cep, setCep] = useState("");
    const [neighboor, setNeighboor] = useState("");
    const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
    const [cellphone, setCellphone] = useState("");
    const [email, setEmail] = useState("");
    const [rg, setRg] = useState("");
    const [cpf, setCpf] = useState("");
    const [scholarity, setScholarity] = useState("");
    const [civilState, setCivilState] = useState("");

    const [rf, setRf] = useState("");
    const [secretary, setSecretary] = useState("");
    const [work, setWork] = useState("");
    const [department, setDepartment] = useState("");
    const [sector, setSector] = useState("");
    const [turn, setTurn] = useState("");
    const [workFunction, setWorkFunction] = useState("");
    const [filliationDate, setFilliationDate] = useState(new Date());
    const [admissionDate, setAdmissionDate] = useState(new Date());
    const [unityCode, setUnityCode] = useState("");
    const [unityEmail, setUnityEmail] = useState("");
    const [unityPhone, setUnityPhone] = useState("");

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    async function sendMail() {
        const data = {
            name,
            sex,
            etnicity,
            birthday,
            disability,
            disabilityType,
            address,
            number,
            complement,
            cep,
            neighboor,
            city,
            phone,
            cellphone,
            email,
            rg,
            cpf,
            scholarity,
            civilState,
            rf,
            secretary,
            work,
            department,
            sector,
            turn,
            workFunction,
            filliationDate,
            admissionDate,
            unityCode,
            unityEmail,
            unityPhone
        };

        await axios.post(
            `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/update-infos`,
            data,
            {
                headers: {
                    "Content-Type": "application/json;charset=utf-8",
                },
                crossDomain: true,
            }
        );

        alert("Dados enviados com sucesso!")
        navigate(`/`)
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Grid
                backgroundColor={!isEditing ? "#FFFFFF" : "#EAECF0"}
                borderRadius={2}
                paddingBottom={8}
                marginTop={4}
                paddingX={isEditing || isMobile ? 4 : 48}
                gap={2}
                rowGap={2}
                container
            >
                <Grid xs={12} md={11.12} marginTop={2}>
                    <Typography
                        color={"#EB2227"}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="22px"
                        lineHeight="18px"
                        marginLeft={1}
                    >
                        Atualize seu cadastro
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12} marginTop={4}>
                    <Typography
                        color={"#202020"}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="18px"
                        marginLeft={1}
                    >
                        Dados Pessoais
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12} marginTop={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Nome/Nome Social"}
                        variant="outlined"
                        type="text"
                        name="name"
                        aria-label="name"
                        fullWidth
                        defaultValue={""}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Data de nascimento"}
                        variant="outlined"
                        type="date"
                        name="birthday"
                        aria-label="birthday"
                        fullWidth
                        value={birthday}
                        onChange={(e) => setBirthday(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Sexo
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={sex}
                            label="Sexo"
                            style={{
                                backgroundColor: isEditing
                                    ? "#FFFFFF"
                                    : "#EAECF0",
                            }}
                            onChange={(e) => setSex(e.target.value)}
                        >
                            <MenuItem key={0} value={"Masculino"}>
                                Masculino
                            </MenuItem>
                            <MenuItem key={0} value={"Feminino"}>
                                Feminino
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={3.6}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Raça/Etnia
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={etnicity}
                            label="Raça/Etnia"
                            style={{
                                backgroundColor: isEditing
                                    ? "#FFFFFF"
                                    : "#EAECF0",
                            }}
                            onChange={(e) => setEtnicity(e.target.value)}
                        >
                            <MenuItem key={0} value={"Branco"}>
                                Branco
                            </MenuItem>
                            <MenuItem key={0} value={"Preto"}>
                                Preto
                            </MenuItem>
                            <MenuItem key={0} value={"Pardo"}>
                                Pardo
                            </MenuItem>
                            <MenuItem key={0} value={"Amarelo"}>
                                Amarelo
                            </MenuItem>
                            <MenuItem key={0} value={"Indígena"}>
                                Indígena
                            </MenuItem>
                            <MenuItem key={0} value={"Não Declarar"}>
                                Não Declarar
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={5.5}>
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                            Possui alguma deficiência?
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            fullWidth
                            defaultValue={"Sim"}
                            onChange={(event) => {
                                setDisability(event.target.value);
                            }}
                        >
                            <FormControlLabel
                                value="Sim"
                                control={<Radio />}
                                label="Sim"
                            />
                            <FormControlLabel
                                value="Não"
                                control={<Radio />}
                                label="Não"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {disability === "Sim" && (
                    <Grid xs={12} md={5.5}>
                        <TextField
                            id="outlined-basic"
                            label={"Se sim, qual?"}
                            variant="outlined"
                            type="text"
                            name="disability"
                            aria-label="disability"
                            fullWidth
                            value={disabilityType}
                            onChange={(e) => setDisabilityType(e.target.value)}
                            style={{
                                backgroundColor: isEditing
                                    ? "#FFFFFF"
                                    : "#EAECF0",
                            }}
                        />
                    </Grid>
                )}
                <Grid xs={12} md={11.12}>
                    <TextField
                        id="outlined-basic"
                        label={"Endereço"}
                        variant="outlined"
                        type="text"
                        name="address"
                        aria-label="address"
                        fullWidth
                        defaultValue={""}
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>

                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"CEP"}
                        variant="outlined"
                        type="number"
                        name="cep"
                        aria-label="cep"
                        fullWidth
                        value={cep}
                        onChange={(e) => setCep(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>

                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Bairro"}
                        variant="outlined"
                        type="text"
                        name="neighboor"
                        aria-label="neighboor"
                        fullWidth
                        defaultValue={""}
                        value={neighboor}
                        onChange={(e) => setNeighboor(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Número"}
                        variant="outlined"
                        type="number"
                        name="number"
                        aria-label="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        fullWidth
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Complemento"}
                        variant="outlined"
                        type="text"
                        name="complement"
                        aria-label="complement"
                        fullWidth
                        defaultValue={""}
                        value={complement}
                        onChange={(e) => setComplement(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Cidade"}
                        variant="outlined"
                        type="text"
                        name="city"
                        aria-label="city"
                        fullWidth
                        defaultValue={""}
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Telefone"}
                        variant="outlined"
                        type="text"
                        name="phone"
                        aria-label="phone"
                        fullWidth
                        defaultValue={""}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Celular"}
                        variant="outlined"
                        type="tel"
                        name="phone"
                        aria-label="phone"
                        fullWidth
                        defaultValue={""}
                        value={cellphone}
                        onChange={(e) => setCellphone(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={11.12}>
                    <TextField
                        id="outlined-basic"
                        label={"E-mail"}
                        variant="outlined"
                        type="email"
                        name="busca"
                        aria-label="busca"
                        fullWidth
                        defaultValue={""}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"CPF"}
                        variant="outlined"
                        type="number"
                        name="cpf"
                        aria-label="cpf"
                        fullWidth
                        defaultValue={""}
                        value={cpf}
                        onChange={(e) => setCpf(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"RG"}
                        variant="outlined"
                        type="number"
                        name="rg"
                        aria-label="rg"
                        fullWidth
                        defaultValue={""}
                        value={rg}
                        onChange={(e) => setRg(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Estado civil
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={civilState}
                            label="Estado civil"
                            style={{
                                backgroundColor: isEditing
                                    ? "#FFFFFF"
                                    : "#EAECF0",
                            }}
                            onChange={(e) => setCivilState(e.target.value)}
                        >
                            <MenuItem key={0} value={"Solteiro (a)"}>
                                Solteiro (a)
                            </MenuItem>
                            <MenuItem key={0} value={"Casado (a)"}>
                                Casado (a)
                            </MenuItem>
                            <MenuItem key={0} value={"Separado (a)"}>
                                Separado (a)
                            </MenuItem>
                            <MenuItem key={0} value={"Divorciado (a)"}>
                                Divorciado (a)
                            </MenuItem>
                            <MenuItem key={0} value={"Viúvo (a)"}>
                                Viúvo (a)
                            </MenuItem>
                            <MenuItem key={0} value={"Outro"}>
                                Outro
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={5.5}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Escolaridade
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={scholarity}
                            label="Escolaridade"
                            style={{
                                backgroundColor: isEditing
                                    ? "#FFFFFF"
                                    : "#EAECF0",
                            }}
                            onChange={(e) => setScholarity(e.target.value)}
                        >
                            <MenuItem key={0} value={"Graduação"}>
                                Graduação
                            </MenuItem>
                            <MenuItem key={0} value={"Pós-Graduação"}>
                                Pós-Graduação
                            </MenuItem>
                            <MenuItem key={0} value={"MBA"}>
                                MBA
                            </MenuItem>
                            <MenuItem key={0} value={"Mestrado"}>
                                Mestrado
                            </MenuItem>
                            <MenuItem key={0} value={"Doutorado"}>
                                Doutorado
                            </MenuItem>
                            <MenuItem key={0} value={"Pós-Doutorado"}>
                                Pós-Doutorado
                            </MenuItem>
                            <MenuItem key={0} value={"Livre Docência"}>
                                Livre Docência
                            </MenuItem>
                            <MenuItem key={0} value={"Técnico"}>
                                Técnico
                            </MenuItem>
                            <MenuItem
                                key={0}
                                value={"Ens. Fundamental Completo"}
                            >
                                Ens. Fundamental Completo
                            </MenuItem>
                            <MenuItem key={0} value={"Ens. Médio Completo"}>
                                Ens. Médio Completo
                            </MenuItem>
                            <MenuItem key={0} value={"Outro"}>
                                Outro
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} md={11.12} marginTop={4}>
                    <Typography
                        color={"#202020"}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="18px"
                        marginLeft={1}
                    >
                        Dados Profissionais
                    </Typography>
                </Grid>
                <Grid xs={12} md={11.12} marginTop={4}>
                    <TextField
                        id="outlined-basic"
                        label={"RF (Registro Funcional)"}
                        variant="outlined"
                        type="number"
                        name="funtionalRegister"
                        aria-label="funtionalRegister"
                        fullWidth
                        defaultValue={""}
                        value={rf}
                        onChange={(e) => setRf(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Secretaria"}
                        variant="outlined"
                        type="text"
                        name="secretary"
                        aria-label="secretary"
                        fullWidth
                        defaultValue={""}
                        value={secretary}
                        onChange={(e) => setSecretary(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Unidade de trabalho"}
                        variant="outlined"
                        type="text"
                        name="workLocal"
                        aria-label="workLocal"
                        fullWidth
                        value={work}
                        onChange={(e) => setWork(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>

                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Departamento"}
                        variant="outlined"
                        type="text"
                        name="department"
                        aria-label="department"
                        fullWidth
                        value={department}
                        onChange={(e) => setDepartment(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Setor"}
                        variant="outlined"
                        type="text"
                        name="sector"
                        aria-label="sector"
                        fullWidth
                        defaultValue={""}
                        value={sector}
                        onChange={(e) => setSector(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={3.6}>
                    <TextField
                        id="outlined-basic"
                        label={"Turno"}
                        variant="outlined"
                        type="text"
                        name="turn"
                        aria-label="turn"
                        fullWidth
                        defaultValue={""}
                        value={turn}
                        onChange={(e) => setTurn(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={11.12}>
                    <TextField
                        id="outlined-basic"
                        label={"Cargo/Função"}
                        variant="outlined"
                        type="text"
                        name="function"
                        aria-label="function"
                        fullWidth
                        defaultValue={""}
                        value={workFunction}
                        onChange={(e) => setWorkFunction(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Data de Filiação"}
                        variant="outlined"
                        type="date"
                        name="filliationDate"
                        aria-label="filliationDate"
                        fullWidth
                        value={filliationDate}
                        onChange={(e) => setFilliationDate(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}>
                    <TextField
                        id="outlined-basic"
                        label={"Data de admissão"}
                        variant="outlined"
                        type="date"
                        name="admissionDate"
                        aria-label="admissionDate"
                        fullWidth
                        value={admissionDate}
                        onChange={(e) => setAdmissionDate(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={11.12} marginTop={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Código do Endereçamento da Unidade"}
                        variant="outlined"
                        type="text"
                        name="unityCode"
                        aria-label="unityCode"
                        fullWidth
                        defaultValue={""}
                        value={unityCode}
                        onChange={(e) => setUnityCode(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={11.12} marginTop={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Email da Unidade "}
                        variant="outlined"
                        type="text"
                        name="unityEmail"
                        aria-label="unityEmail"
                        fullWidth
                        defaultValue={""}
                        value={unityEmail}
                        onChange={(e) => setUnityEmail(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={11.12} marginTop={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Telefone da Unidade"}
                        variant="outlined"
                        type="text"
                        name="unityPhone"
                        aria-label="unityPhone"
                        fullWidth
                        defaultValue={""}
                        value={unityPhone}
                        onChange={(e) => setUnityPhone(e.target.value)}
                        style={{
                            backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                        }}
                    />
                </Grid>
                <Grid xs={12} md={5.5}></Grid>
                <Grid xs={12} md={5.5}>
                    <Button
                        fullWidth
                        style={{
                            borderRadius: 32,
                            marginTop: 32,
                            textAlign: "center",
                            backgroundColor: "#EB2227",
                        }}
                        onClick={sendMail}
                    >
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            padding={2}
                        >
                            ATUALIZAR CADASTRO
                        </Typography>
                    </Button>
                </Grid>
            </Grid>

            <Footer />
            <SiteMap />
        </div>
    );
}

export default EditData;
