import React, { Fragment } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import Logo from "../assets/logo";
import { Box } from "@mui/material";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Link } from "react-router-dom";

import Accessible from "../assets/accessible";

function Header3({ isMobile }) {

    return (
        <Fragment>
            <Toolbar>
                <Link to="/" aria-label="link para home">
                    <Logo />
                </Link>
                {!isMobile && (
                    <Box
                        component="h2"
                        color="inherit"
                        align="center"
                        display={"flex"}
                        justifyContent={"center"}
                        gap={3}
                        noWrap={true}
                        sx={{ flex: 1 }}
                    ></Box>
                )}
                <Typography
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="600"
                    fontSize="14px"
                    lineHeight="16px"
                    color="#EB2227"
                    marginX={2}
                >
                    SIGA O SINDSEP NAS REDES
                </Typography>
                <a
                    href="https://www.facebook.com/sindsep"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do facebook"
                    marginX={2}
                >
                    <Facebook style={{ color: "#EB2227" }} />
                </a>
                <a
                    href="https://www.instagram.com/sindsepsp"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do instagram"
                    style={{marginLeft: 8 }}
                >
                    <Instagram style={{ color: "#EB2227" }} />
                </a>
                <a
                    href="https://twitter.com/sindsep"
                    target={"_blank"}
                    rel="noreferrer"
                    aria-label="redirecionamento para pagina do twitter"
                    style={{marginLeft: 8 }}
                >
                    <Twitter style={{ color: "#EB2227" }} />
                </a>

                <Box
                    marginLeft={2}
                    backgroundColor="#004F8F"
                    borderRadius={999}
                    width="20px"
                    height="20px"
                >
                    <Accessible />
                </Box>
                <Typography
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="14px"
                    lineHeight="13px"
                    color="#004F8F"
                    marginX={1}
                    whiteSpace={"pre-line"}
                >
                    {"Site\n acessível"}
                </Typography>
            </Toolbar>
        </Fragment>
    );
}

export default Header3;
