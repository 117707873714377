import React, { Fragment, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import { Button, Grid, Typography } from "@mui/material";
import NewsItemLateral from "../components/commons/NewsItemLateral";
import axios from "axios";

const Congress = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [news, setNews] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        const loadNews = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/news?categorie=13o CONGRESSO&limit=20&page=${page}`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            if (page !== 0) {
                const append = news.concat(resp.data);

                setNews(append);
            } else {
                setNews(resp.data);
            }
        };

        loadNews();
    }, [page]);

    async function loadMore() {
        setPage(page + 1);
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        {"13º CONGRESSO"}
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid
                        container
                        marginY={6}
                        gap={2}
                        display={"flex"}
                        position={"relative"}
                    >
                        {news.map((item) => (
                            <Grid
                                xs={12}
                                md={4.5}
                                display={"flex"}
                                padding={"0px 10px"}
                            >
                                <NewsItemLateral
                                    image={
                                        item.capa && item.capa.includes("aws")
                                            ? item.capa
                                            : `https://admin.sindsep-sp.org.br/sistema/noticias/${item.ID}/capa/${item.capa}`
                                    }
                                    date={item.incData}
                                    description={item.titulo}
                                    link={item.link}
                                    categorie={item.categorie}
                                    categorieColor={item.categorieColor}
                                    isMobile={isMobile}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    onClick={() => loadMore()}
                    style={{
                        borderRadius: 100,
                        color: "#EB2227",
                        padding: "8px 16px",
                        fontFamily: "Raleway",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    VER MAIS NOTÍCIAS
                </Button>
            </Grid>
            </Fragment>
            <Footer />
            <SiteMap />
        </div>
    );
};

export default Congress;
