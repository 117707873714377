import React, { Fragment, useEffect, useState } from "react";

import {
    Box,
    Button,
    Grid,
    Typography,
} from "@mui/material";

import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";

function Benefits() {
    const [searchParams] = useSearchParams();
    const [selected, setSelected] = useState(searchParams.get("benefits") ? searchParams.get("benefits") : "");
    const [menu, setMenu] = useState([]);
    const [services, setServices] = useState([]);
    
    const [page, setPage] = useState(4);

    async function loadMore() {
        setPage(page + 4);
    }

    useEffect(() => {
        const loadServices = async () => {
            searchParams.set("benefits", selected);

            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/services?limit=300`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setServices(resp.data);

            const aux = [];

            resp.data.map((obj, index) => {
                if (
                    resp.data
                        .map((item) => item.categoria)
                        .indexOf(obj.categoria) === index
                )
                    aux.push(obj.categoria);
                return true;
            });

            aux.sort();

            aux.unshift("TODOS OS BENEFÍCIOS");

            setSelected(
                searchParams.get("benefits") ? searchParams.get("benefits") : ""
            );
            setMenu(aux);

            window.scrollTo({
                top: 0,
                behavior: "smooth", // for smoothly scrolling
            });
        };

        loadServices();
    }, [selected, searchParams]);

    return (
        <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={8}
                marginBottom={32}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Benefícios
                    </Typography>
                </Grid>

                <Grid xs={12} md={9}>
                    <Grid container gap={1} marginTop={1}>
                        {menu.map((item) => (
                            <Grid xs={12} md={3.7}>
                                <Button
                                    onClick={() =>
                                        setSelected(
                                            item === "TODOS OS BENEFÍCIOS"
                                                ? ""
                                                : item
                                        )
                                    }
                                    aria-label="home"
                                    style={{
                                        color: "#202020",
                                        textDecoration: "none",
                                    }}
                                >
                                    <Typography
                                        fontFamily="Raleway"
                                        fontStyle="normal"
                                        textAlign={"left"}
                                        fontWeight={
                                            item === selected ? "700" : "500"
                                        }
                                        fontSize="18px"
                                        lineHeight="20px"
                                        color="#202020"
                                    >
                                        {item}
                                    </Typography>
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                {selected === "" && (
                    <Grid xs={12} md={12} marginTop={4} color="#010101">
                        <Typography
                            color="#010101"
                            width={"fit-content"}
                            borderBottom={1}
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="20px"
                            lineHeight="23px"
                        >
                            Últimas atualizações de benefícios e serviços
                        </Typography>
                    </Grid>
                )}
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid container marginY={6} gap={2} display={"flex"}>
                        {selected !== "" &&
                            services.map(
                                (item) =>
                                    item.categoria === selected && (
                                        <Grid
                                            xs={12}
                                            md={8}
                                            display={"flex"}
                                            marginTop={4}
                                            padding={"0px 10px"}
                                        >
                                            <Box
                                                marginBottom={2}
                                                display="flex"
                                                flexDirection={"row"}
                                                alignItems={"flex-start"}
                                                gap={2}
                                            >
                                                <Box>
                                                    <Typography
                                                        color="#000000"
                                                        fontFamily="Raleway"
                                                        fontStyle="normal"
                                                        fontWeight="700"
                                                        fontSize="32px"
                                                        lineHeight="37.57px"
                                                        marginBottom={2}
                                                    >
                                                        {item.titulo}
                                                    </Typography>
                                                    <Typography
                                                        color="#000000"
                                                        fontFamily="Raleway"
                                                        fontStyle="normal"
                                                        fontWeight="500"
                                                        fontSize="24px"
                                                        lineHeight="24.57px"
                                                        marginBottom={2}
                                                    >
                                                        {item.description}
                                                    </Typography>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.ck,
                                                            
                                                        }}
                                                    ></div>

                                                    <Link
                                                        to={`/servicos/${item._id}`}
                                                        aria-label="link para serviços"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            marginTop: 16,
                                                        }}
                                                    >
                                                        <Button
                                                            size="small"
                                                            variant="outlined"
                                                            style={{
                                                                borderRadius: 100,
                                                                color: "#EB2227",
                                                                padding:
                                                                    "8px 16px",
                                                                fontFamily:
                                                                    "Raleway",
                                                                fontStyle:
                                                                    "normal",
                                                                fontWeight:
                                                                    "600",
                                                                fontSize:
                                                                    "16px",
                                                                lineHeight:
                                                                    "20px",
                                                                borderColor:
                                                                    "#EB2227",
                                                                marginTop: 16,
                                                            }}
                                                        >
                                                            {item.categoria ===
                                                            "Colônias de Férias"
                                                                ? "Ver Galeria"
                                                                : "Ver Página"}
                                                        </Button>
                                                    </Link>
                                                </Box>
                                                {item.capa &&
                                                    item.capa !== "" && (
                                                        <Link
                                                            to={`/servicos/${item._id}`}
                                                            aria-label="link para serviços"
                                                            style={{
                                                                width: "200px",
                                                            }}
                                                        >
                                                            <img
                                                                src={item.capa}
                                                                width={"300px"}
                                                                alt="imagem para serviços do sindsep"
                                                                style={{
                                                                    objectFit:
                                                                        "contain",
                                                                }}
                                                            />
                                                        </Link>
                                                    )}
                                            </Box>
                                        </Grid>
                                    )
                            )}
                        {selected === "" &&
                            services.slice(0, page).map((item) => (
                                <Grid
                                    xs={12}
                                    md={2.7}
                                    display={"flex"}
                                    marginTop={4}
                                    padding={"0px 10px"}
                                >
                                    <Link
                                        to={`/servicos/${item._id}`}
                                        aria-label="link para home"
                                        style={{
                                            width: "80%",
                                            display: "flex",
                                        }}
                                    >
                                        <Box
                                            marginBottom={2}
                                            display="flex"
                                            flexDirection={"row"}
                                            gap={2}
                                        >
                                            {item.capa && item.capa !== "" && (
                                                <img
                                                    src={item.capa}
                                                    alt="imagem para serviços do sindsep"
                                                    style={{
                                                        width: "80%",
                                                        objectFit: "contain",
                                                    }}
                                                />
                                            )}
                                            {item.idCat === 65 && (
                                                <Link
                                                    to={`/servicos/${item._id}`}
                                                    aria-label="link para home"
                                                    style={{
                                                        width: "80%",
                                                    }}
                                                >
                                                    <img
                                                        src={`https://servicos-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpg`}
                                                        alt="imagem para serviços do sindsep"
                                                        style={{
                                                            width: "80%",
                                                            objectFit:
                                                                "contain",
                                                        }}
                                                    />
                                                </Link>
                                            )}
                                        </Box>
                                    </Link>
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
                {selected === "" && (
                    <Grid
                        xs={12}
                        md={12}
                        padding={"16px 0px"}
                        color="#FFFFFF"
                        borderBottom={1}
                        display={"flex"}
                        justifyContent={"center"}
                    >
                        <Button
                            size="small"
                            color="warning"
                            variant="outlined"
                            onClick={() => loadMore()}
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}
                        >
                            VER MAIS BENEFÍCIOS
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Fragment>
    );
}

export default Benefits;
