import { Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import VideoItem from "./commons/VideoItem";

import { useSearchParams } from "react-router-dom";
import axios from "axios";

function AllVideos({ isMobile }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [videos, setVideos] = useState([]);
    const [page, setPage] = useState(
        searchParams.get("page") ? Number(searchParams.get("page")) : 1
    );

    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${
            months[newDate.getMonth()]
        } de ${newDate.getFullYear()}`;
    }

    useEffect(() => {
        const loadMaterials = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/videos?page=0&limit=${
                    searchParams.get("page")
                        ? Number(searchParams.get("page")) * 21
                        : page * 21
                }`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setVideos(resp.data);
        };

        loadMaterials();
    }, [searchParams, page]);

    async function loadMore() {
        setSearchParams({ page: page + 1 });
        setPage(page + 1);
    }

    return (
        <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Vídeos
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid container marginY={6} gap={2} display={"flex"}>
                        {videos.map((item) => (
                            <Grid
                                xs={12}
                                md={3.5}
                                display={"flex"}
                                padding={"0px 10px"}
                            >
                                <VideoItem
                                    image={`https://img.youtube.com/vi/${item.youtube}/hqdefault.jpg`}
                                    date={formatDate(item.incData)}
                                    description={item.titulo}
                                    link={`https://youtube.com/watch?v=${item.youtube}`}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    onClick={() => loadMore()}
                    style={{
                        borderRadius: 100,
                        color: "#EB2227",
                        padding: "8px 16px",
                        fontFamily: "Raleway",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "20px",
                    }}
                >
                    VER MAIS VÍDEOS
                </Button>
            </Grid>
        </Fragment>
    );
}

export default AllVideos;
