const Benefits = () => {
    return (
        <svg
            width="33"
            height="26"
            viewBox="0 0 33 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.933577 10.3304C0.2058 9.34156 0.416911 7.95267 1.4058 7.22489L8.43913 2.04156C9.73358 1.086 11.3058 0.569336 12.9169 0.569336H30.7225C31.7058 0.569336 32.5002 1.36378 32.5002 2.34711V5.90267C32.5002 6.886 31.7058 7.68045 30.7225 7.68045H28.678L26.1836 9.68045C24.9225 10.6916 23.3558 11.236 21.7391 11.236H12.9447C11.9614 11.236 11.1669 10.4416 11.1669 9.45823C11.1669 8.47489 11.9614 7.68045 12.9447 7.68045H17.3891C17.878 7.68045 18.278 7.28045 18.278 6.79156C18.278 6.30267 17.878 5.90267 17.3891 5.90267H10.6891L4.03913 10.8027C3.05024 11.5304 1.66136 11.3193 0.933577 10.3304ZM21.7447 7.68045H21.7947H21.7447ZM32.0669 15.6971C32.7947 16.686 32.5836 18.0749 31.5947 18.8027L24.5614 23.986C23.2614 24.9416 21.6947 25.4582 20.078 25.4582H2.27802C1.29469 25.4582 0.500244 24.6638 0.500244 23.6804V20.1249C0.500244 19.1416 1.29469 18.3471 2.27802 18.3471H4.32247L6.81691 16.3471C8.07802 15.336 9.64469 14.7916 11.2614 14.7916H20.0558C21.0391 14.7916 21.8336 15.586 21.8336 16.5693C21.8336 17.5527 21.0391 18.3471 20.0558 18.3471H15.6114C15.1225 18.3471 14.7225 18.7471 14.7225 19.236C14.7225 19.7249 15.1225 20.1249 15.6114 20.1249H22.3114L28.9614 15.2249C29.9502 14.4971 31.3391 14.7082 32.0669 15.6971ZM11.2558 18.3471H11.2058H11.2558Z"
                fill="#202020"
            />
        </svg>
    );
};

export default Benefits;
