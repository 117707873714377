const Presentation = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.4286 40H8.57143V35.7143H2.85714V40H0V35.7143C0.000756395 34.9568 0.302019 34.2305 0.837673 33.6948C1.37333 33.1592 2.09961 32.8579 2.85714 32.8572H8.57143C9.32896 32.8579 10.0552 33.1592 10.5909 33.6948C11.1266 34.2305 11.4278 34.9568 11.4286 35.7143V40ZM40 40H37.1429V35.7143H31.4286V40H28.5714V35.7143C28.5722 34.9568 28.8734 34.2305 29.4091 33.6948C29.9448 33.1592 30.671 32.8579 31.4286 32.8572H37.1429C37.9004 32.8579 38.6267 33.1592 39.1623 33.6948C39.698 34.2305 39.9992 34.9568 40 35.7143V40ZM25.7143 40H22.8571V35.7143H17.1429V40H14.2857V35.7143C14.2865 34.9568 14.5877 34.2305 15.1234 33.6948C15.659 33.1592 16.3853 32.8579 17.1429 32.8572H22.8571C23.6147 32.8579 24.341 33.1592 24.8766 33.6948C25.4123 34.2305 25.7135 34.9568 25.7143 35.7143V40Z"
                fill="#202020"
            />
            <path
                d="M20 31.4286C21.5779 31.4286 22.8571 30.1495 22.8571 28.5715C22.8571 26.9935 21.5779 25.7144 20 25.7144C18.422 25.7144 17.1428 26.9935 17.1428 28.5715C17.1428 30.1495 18.422 31.4286 20 31.4286Z"
                fill="#202020"
            />
            <path
                d="M5.71432 31.4286C7.29228 31.4286 8.57146 30.1495 8.57146 28.5715C8.57146 26.9935 7.29228 25.7144 5.71432 25.7144C4.13636 25.7144 2.85718 26.9935 2.85718 28.5715C2.85718 30.1495 4.13636 31.4286 5.71432 31.4286Z"
                fill="#202020"
            />
            <path
                d="M34.2856 31.4286C35.8636 31.4286 37.1428 30.1495 37.1428 28.5715C37.1428 26.9935 35.8636 25.7144 34.2856 25.7144C32.7077 25.7144 31.4285 26.9935 31.4285 28.5715C31.4285 30.1495 32.7077 31.4286 34.2856 31.4286Z"
                fill="#202020"
            />
            <path
                d="M27.1428 25.7143C28.7207 25.7143 29.9999 24.4351 29.9999 22.8571C29.9999 21.2792 28.7207 20 27.1428 20C25.5648 20 24.2856 21.2792 24.2856 22.8571C24.2856 24.4351 25.5648 25.7143 27.1428 25.7143Z"
                fill="#202020"
            />
            <path
                d="M12.8571 25.7143C14.4351 25.7143 15.7143 24.4351 15.7143 22.8571C15.7143 21.2792 14.4351 20 12.8571 20C11.2792 20 10 21.2792 10 22.8571C10 24.4351 11.2792 25.7143 12.8571 25.7143Z"
                fill="#202020"
            />
            <path
                d="M34.2857 17.1429H5.71432C4.95679 17.1421 4.23051 16.8408 3.69485 16.3052C3.1592 15.7695 2.85793 15.0432 2.85718 14.2857V2.85714C2.85793 2.09961 3.1592 1.37333 3.69485 0.837673C4.23051 0.302019 4.95679 0.000756395 5.71432 0H34.2857C35.0433 0.000756395 35.7696 0.302019 36.3052 0.837673C36.8409 1.37333 37.1421 2.09961 37.1429 2.85714V14.2857C37.1421 15.0432 36.8409 15.7695 36.3052 16.3052C35.7696 16.8408 35.0433 17.1421 34.2857 17.1429ZM5.71432 2.85714V14.2857H34.2857V2.85714H5.71432Z"
                fill="#202020"
            />
        </svg>
    );
};

export default Presentation;
