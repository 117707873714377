import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import Director from "../assets/director";
import Lib from "../assets/lib";
import School from "../assets/school";
import { Link } from "react-router-dom";

function Services() {
    return (
        <Fragment>
            <Grid
                container
                marginTop={6}
                gap={4}
                display={"flex"}
                paddingX={2}
                justifyContent={"center"}
            >
                <Grid xs={12} md={3.5}>
                    <a
                        aria-label="redirecionamento para escola sindsep"
                        href="https://www.escolasindsep.com.br"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            backgroundColor={"#EB2227"}
                            borderRadius={"12px"}
                            padding={"16px 34px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <School />
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                padding={"14px"}
                            >
                                <Typography
                                    textAlign={"center"}
                                    color="#FFFFFF"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="26px"
                                    lineHeight="30px"
                                >
                                    ESCOLA DE FORMAÇÃO SINDSEP
                                </Typography>
                                <Button
                                    size="small"
                                    style={{
                                        backgroundColor: "#202020",
                                        borderRadius: 100,
                                        color: "#FFFFFF",
                                        marginTop: 16,
                                        padding: "8px 16px",
                                        fontFamily: "Raleway",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                    }}
                                >
                                    SAIBA MAIS
                                </Button>
                            </Box>
                        </Box>
                    </a>
                </Grid>
                <Grid xs={12} md={3.5}>
                    <Link
                        aria-label="diretoria"
                        to="/diretoria"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            backgroundColor={"#FFC700"}
                            borderRadius={"12px"}
                            padding={"16px 34px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <Director />
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                padding={"14px"}
                            >
                                <Typography
                                    color="#FFFFFF"
                                    textAlign={"center"}
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="26px"
                                    lineHeight="30px"
                                >
                                    {`
                                    DIRETORIA DO SINDSEP`}
                                </Typography>
                                <Button
                                    size="small"
                                    style={{
                                        backgroundColor: "#FFFFFF",
                                        borderRadius: 100,
                                        color: "#202020",
                                        marginTop: 16,
                                        padding: "8px 16px",

                                        fontFamily: "Raleway",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                    }}
                                >
                                    VEJA AQUI
                                </Button>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={12} md={3.5}>
                    <Link
                        to="/materiais"
                        aria-label="link para noticias"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            backgroundColor={"#EB2227"}
                            borderRadius={"12px"}
                            padding={"16px 34px"}
                            display={"flex"}
                            alignItems={"center"}
                            gap={2}
                        >
                            <Lib />
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                justifyContent={"center"}
                                alignItems={"center"}
                                padding={"14px"}
                            >
                                <Typography
                                    textAlign={"center"}
                                    color="#FFFFFF"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="26px"
                                    lineHeight="30px"
                                >
                                    {`
                                    BIBLIOTECA DE MATERIAIS`}
                                </Typography>
                                <Button
                                    size="small"
                                    style={{
                                        backgroundColor: "#202020",
                                        borderRadius: 100,
                                        color: "#FFFFFF",
                                        marginTop: 16,
                                        padding: "8px 16px",
                                        fontFamily: "Raleway",
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        fontSize: "16px",
                                        lineHeight: "22px",
                                    }}
                                >
                                    ACESSE AQUI
                                </Button>
                            </Box>
                        </Box>
                    </Link>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Services;
