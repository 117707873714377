import { Button, Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import MaterialItem from "./commons/MaterialItem";

import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";

import { useSearchParams } from "react-router-dom";
import axios from "axios";

function AllMaterials({ isMobile }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [materials, setMaterials] = useState([]);

    useEffect(() => {
        setSearchParams({ page: Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1 });
        loadPage(Number(searchParams.get("page")))
    }, [searchParams]);

    async function loadPage(number) {
        setSearchParams({ page: number });
        if (number <= 0) {
            setSearchParams({ page: 1 });
            number = 1
        }

        const loadMaterials = async (number) => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/materials?page=${number-1}&${
                    !searchParams.get("categorie") ||
                    searchParams.get("categorie") === "0"
                        ? ""
                        : `categorie=${searchParams.get("categorie")}`
                }`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setMaterials(resp.data);
        };

        loadMaterials(number);

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }

    return (
        <Fragment>
            <Grid
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        Materiais
                    </Typography>
                </Grid>
                <Grid xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid container marginY={6} gap={2} display={"flex"}>
                        {materials.map((item) => (
                            <Grid
                                xs={12}
                                md={3.5}
                                display={"flex"}
                                padding={"0px 10px"}
                            >
                                <MaterialItem
                                    image={
                                        item.capa.includes("aws")
                                            ? item.capa
                                            : `https://materiais-bucket.s3.sa-east-1.amazonaws.com/${item.ID}/capa/${item.capa}`
                                    }
                                    date={item.data}
                                    description={item.titulo}
                                    link={item.link}
                                    categorie={item.categorie}
                                    categorieColor={item.categorieColor}
                                    isMobile={isMobile}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            
            <Grid
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Pagination
                    color="warning"
                    variant="outlined" count={130} page={Number(searchParams.get("page"))} onChange={(e, number) => loadPage(number)}
                    renderItem={(item) => (
                        <PaginationItem
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}

                            slots={{ previous: ArrowBackOutlined, next: ArrowForwardOutlined }}
                            {...item}
                        />)}
                />
            </Grid>
        </Fragment>
    );
}

export default AllMaterials;
