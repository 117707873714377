import { Box, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { WhatsApp } from "@mui/icons-material";

function Linares() {
    return (
        <Fragment>
            <Grid container marginY={6} gap={2} display={"flex"} paddingX={8} marginBottom={32}>
                <Grid
                    xs={12}
                    md={12}
                    marginTop={4}
                    color="#010101"
                    borderBottom={1}
                >
                    <Typography
                        color="#010101"
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                    >
                        ASSESSORIA DE IMPRENSA
                    </Typography>
                </Grid>
                <Typography
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#202020"
                    whiteSpace={"pre-line"}
                >
                    {`Informações e pautas:

            Alexandre Linares 
            `}
                    <Box display={'flex'} alignItems={'center'} ><WhatsApp style={{ width: "16px", color: "#202020" }} /> {`(11) 99900-0088`}</Box>
                    {`imprensa@sindsep-sp.org.br`}
                </Typography>
            </Grid>
        </Fragment>
    );
}

export default Linares;
