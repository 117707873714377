import React, { useEffect, useState } from "react";
import PhotoFullPage from "../components/commons/PhotoFullPage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import axios from "axios";
import { useSearchParams } from "react-router-dom";

const PhotoItemId = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [photo, setPhoto] = useState(null);
    const [searchParams] = useSearchParams();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadPhoto = async () => {
            const galleryData = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/gallery/links/find?link=${searchParams.get("link")}`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );
            
            setPhoto(galleryData.data[0]);
        };

        loadPhoto();

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            {photo && <PhotoFullPage photo={photo} />}
            <Footer />
            <SiteMap />
        </div>
    );
};

export default PhotoItemId;
