import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { Box, Drawer, Button, List, ListItem, Menu } from "@mui/material";
import Logo from "../assets/logo";

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

function MenuComponent() {

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    return (
        <div>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
                    <List style={{ padding: 16}}>
                        <Logo />
                        <ListItem onClick={() => navigate(`/`)} style={{ marginTop: 16 }}>
                            Página Inicial
                        </ListItem>
                        <ListItem onClick={() => navigate(`/diretoria`)}>
                            O Sindsep
                        </ListItem>
                        <ListItem>
                            <a
                                href="https://sindicalizacao.sindsep-sp.org.br/"
                                rel="noreferrer"
                                target={"_blank"}
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}
                            >
                                Sindicalização
                            </a>
                        </ListItem>
                        {/* att
                        <ListItem>
                            <Link
                                to="/atualize-seu-cadastro"
                                aria-label="link para atualização cadastral"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}
                            >
                                Atualização de Cadastro
                            </Link>
                        </ListItem>*/}
                        <ListItem>
                            <Link
                                to="/servicos"
                                aria-label="link para noticias"
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}
                            >
                                Benefícios
                            </Link>
                        </ListItem>
                        <ListItem
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                                cursor: "pointer"
                            }}
                            onClick={() => navigate(`/noticias`)}
                        >
                            Notícias
                        </ListItem>
                        <ListItem>
                            <a
                                href="https://www.youtube.com/c/SindsepSãoPaulo"
                                rel="noreferrer"
                                target={"_blank"}
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}
                            >
                                TV Sindsep
                            </a>
                        </ListItem>
                        <ListItem
                            style={{
                                color: "#202020",
                                textDecoration: "none",
                                cursor: "pointer"
                            }}
                            onClick={() => navigate(`/galeria`)}
                        >
                            Fotos
                        </ListItem>
                        <ListItem>
                            <a
                                href="https://api.whatsapp.com/send?phone=5511970255497"
                                rel="noreferrer"
                                target={"_blank"}
                                style={{
                                    color: "#202020",
                                    textDecoration: "none",
                                    cursor: "pointer"
                                }}
                            >
                                Fale conosco
                            </a>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Button
                id="demo-customized-button"
                aria-controls={open ? "demo-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="text"
                disableElevation
                onClick={toggleDrawer(true)}
                startIcon={<MenuIcon />}
                style={{
                    color: "#EB2227",
                    fontFamily: "Raleway",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "20px",
                }}
            >
                Menu
            </Button>
        </div>
    );
}

export default MenuComponent;
