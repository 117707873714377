const ServiceArea = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24.0002 32.0002H21.7145V29.7145C21.7135 28.8054 21.352 27.9338 20.7092 27.2911C20.0664 26.6483 19.1949 26.2867 18.2858 26.2858H13.7144C12.8053 26.2867 11.9338 26.6483 11.291 27.2911C10.6482 27.9338 10.2866 28.8054 10.2857 29.7145V32.0002H8V29.7145C8.00181 28.1995 8.60444 26.7471 9.6757 25.6758C10.747 24.6045 12.1994 24.0019 13.7144 24.0001H18.2858C19.8008 24.0019 21.2532 24.6045 22.3245 25.6758C23.3958 26.7471 23.9984 28.1995 24.0002 29.7145V32.0002ZM16.0001 12.5714C16.6782 12.5714 17.3411 12.7725 17.9049 13.1492C18.4688 13.526 18.9082 14.0614 19.1677 14.6879C19.4272 15.3144 19.4951 16.0038 19.3628 16.6689C19.2305 17.334 18.904 17.9449 18.4245 18.4244C17.945 18.9039 17.3341 19.2304 16.669 19.3627C16.0039 19.495 15.3145 19.4271 14.688 19.1676C14.0615 18.9081 13.5261 18.4687 13.1493 17.9048C12.7726 17.341 12.5715 16.6781 12.5715 16C12.5715 15.0907 12.9327 14.2186 13.5757 13.5756C14.2187 12.9326 15.0908 12.5714 16.0001 12.5714ZM16.0001 10.2856C14.8699 10.2856 13.7651 10.6208 12.8254 11.2487C11.8857 11.8766 11.1532 12.769 10.7207 13.8132C10.2882 14.8574 10.1751 16.0063 10.3955 17.1148C10.616 18.2233 11.1603 19.2415 11.9594 20.0407C12.7586 20.8398 13.7768 21.3841 14.8853 21.6046C15.9938 21.825 17.1427 21.7119 18.1869 21.2794C19.231 20.8469 20.1235 20.1144 20.7514 19.1747C21.3793 18.235 21.7145 17.1302 21.7145 16C21.7145 14.4845 21.1124 13.031 20.0408 11.9593C18.9691 10.8877 17.5156 10.2856 16.0001 10.2856Z"
                fill="#010101"
            />
            <path
                d="M32.0004 32H29.7146V14.2444L16.0002 2.63965L2.28574 14.2444V32H0V13.7141C5.60218e-05 13.5481 0.0362781 13.384 0.106147 13.2335C0.176016 13.0829 0.277854 12.9493 0.404576 12.8421L15.2619 0.270475C15.4683 0.0958336 15.7299 0 16.0002 0C16.2705 0 16.5321 0.0958336 16.7385 0.270475L31.5958 12.8421C31.7225 12.9493 31.8244 13.0829 31.8942 13.2335C31.9641 13.384 32.0003 13.5481 32.0004 13.7141V32Z"
                fill="#010101"
            />
        </svg>
    );
};

export default ServiceArea;
