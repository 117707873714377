const Logo = () => {
    return (
        <svg
            width="172"
            height="50"
            viewBox="0 0 172 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M171.273 13.529C171.273 10.572 170.666 8.059 169.471 6.062C168.28 4.069 166.56 2.547 164.357 1.539C162.122 0.518 159.42 0 156.328 0L26.5347 0.028L25.9023 0.056H12.768C8.68346 0.056 5.49846 1.116 3.30008 3.207C1.1107 5.294 0 8.014 0 11.289C0 12.823 0.301189 14.323 0.896564 15.747C1.50295 17.201 2.18838 18.438 2.93284 19.423L6.01078 23.187L15.5578 34.387C15.959 34.819 16.2852 35.257 16.5214 35.68C16.7826 36.156 16.9146 36.661 16.9146 37.184C16.9146 38.143 16.3803 38.806 15.4087 39.049C14.5932 39.245 13.3604 39.339 11.6343 39.339H0.622391L3.31108 49.777H17.9263C18.3215 49.777 18.7208 49.765 19.118 49.741L28.3128 31.183C27.3512 29.763 26.1795 28.307 24.8276 26.851L15.826 16.562C14.7323 15.245 14.1819 14.54 13.9778 14.194C13.7356 13.796 13.6106 13.315 13.6106 12.771C13.6106 11.818 14.1279 11.148 15.0655 10.884C15.857 10.663 17.0667 10.556 18.7648 10.556H29.6236L153.044 10.505C155.429 10.505 157.069 10.814 158.058 11.449C159.101 12.119 159.629 13.235 159.629 14.767C159.629 16.793 158.613 18.819 156.609 20.786C154.667 22.687 152.155 24.536 149.141 26.283L148.416 26.702V12.961H137.004V21.328L144.307 21.317L142.743 28.661L137.004 28.67V31.879L140.215 31.875L137.004 35.932V49.75H148.416V38.636L148.704 38.509C154.92 35.771 160.306 32.225 164.711 27.969C169.065 23.768 171.273 18.91 171.273 13.529Z"
                fill="#EB2227"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M27.0652 49.6946L40.5707 22.4336L33.7194 22.4446L20.2129 49.7046L27.0652 49.6946Z"
                fill="#010101"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.247 22.4006L58.1398 32.7046L53.8151 22.4146L46.9538 22.4246L33.4473 49.6846L40.2956 49.6746L49.1151 31.8726H49.8246L53.5909 41.8906L49.7405 49.6616L56.5878 49.6516L70.0933 22.3906L63.247 22.4006Z"
                fill="#010101"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M89.4853 40.7878C92.183 38.2068 94.2152 35.5078 95.5271 32.7668C97.1501 29.3448 97.4403 26.7238 96.3896 24.9798L96.3886 24.9788C95.3479 23.2428 93.0265 22.3618 89.4893 22.3618C89.4762 22.3618 89.4642 22.3618 89.4512 22.3618L76.7843 22.3798L72.5666 27.7998L84.4061 27.7838C84.4121 27.7838 84.4191 27.7838 84.4261 27.7838C86.5374 27.7838 87.8742 28.2828 88.5136 29.3088C89.1641 30.3548 89.0079 31.8188 88.0343 33.7848C86.8246 36.2268 84.8213 38.5578 82.0806 40.7138C79.3169 42.8868 76.361 44.0738 73.2941 44.2428L72.4686 44.2878L79.7561 29.5828L72.9068 29.5938L62.9756 49.6418L68.6842 49.6328C72.5516 49.6278 76.344 48.7958 79.9563 47.1588C83.5825 45.5128 86.7896 43.3688 89.4853 40.7878Z"
                fill="#010101"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M111.309 22.3295C108.81 22.3315 106.562 22.9265 104.626 24.0965C102.699 25.2655 101.264 26.7795 100.362 28.5965C99.9498 29.4265 99.7287 30.2295 99.7047 30.9835C99.6787 31.7605 99.7527 32.4105 99.9248 32.9145L100.773 34.9895L103.547 41.1975C103.685 41.4685 103.775 41.7485 103.81 42.0185C103.847 42.3435 103.78 42.6724 103.614 43.0024C103.342 43.5504 102.864 43.8995 102.195 44.0374C101.633 44.1474 100.842 44.2025 99.7747 44.2045L93.0125 44.2135L91.8748 49.5965L100.495 49.5835C101.959 49.5815 103.429 49.3495 104.863 48.8925C106.287 48.4385 107.56 47.7585 108.644 46.8715C109.723 45.9845 110.651 44.8185 111.4 43.4095C112.04 42.1595 112.294 41.0515 112.146 40.1235C111.99 39.1375 111.631 38.1105 111.078 37.0685L108.375 31.3315C107.989 30.4155 107.893 30.1065 107.865 29.9205C107.814 29.6515 107.873 29.3395 108.048 28.9825C108.312 28.4415 108.797 28.0775 109.451 27.9305C110.003 27.8055 110.784 27.7435 111.835 27.7415L118.115 27.7315L119.234 22.3145L111.309 22.3295Z"
                fill="#010101"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M125.801 22.3072L112.296 49.5682L130.302 49.5422L134.505 44.1532L121.739 44.1732L124.676 38.2422L133.957 38.2282L138.221 32.8412L127.346 32.8562L129.891 27.7162L141.962 27.6982L143.116 22.2822L125.801 22.3072Z"
                fill="#010101"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M158.612 44.312H159.751C160.296 44.312 160.669 44.231 160.867 44.069C161.065 43.906 161.164 43.691 161.164 43.425C161.164 43.253 161.117 43.098 161.019 42.961C160.926 42.825 160.791 42.724 160.623 42.657C160.455 42.589 160.141 42.556 159.68 42.556H158.613V44.312H158.612ZM157.672 47.587V41.768H159.673C160.358 41.768 160.85 41.822 161.155 41.93C161.462 42.038 161.708 42.225 161.889 42.492C162.069 42.759 162.16 43.044 162.16 43.344C162.16 43.769 162.008 44.14 161.707 44.455C161.401 44.77 160.999 44.947 160.496 44.985C160.703 45.071 160.867 45.174 160.993 45.293C161.227 45.522 161.514 45.906 161.851 46.446L162.563 47.585H161.414L160.9 46.668C160.492 45.947 160.167 45.496 159.917 45.314C159.745 45.181 159.493 45.114 159.164 45.114H158.613V47.586H157.672V47.587ZM160.045 40.085C160.8 40.085 161.538 40.28 162.26 40.669C162.982 41.059 163.545 41.614 163.949 42.338C164.353 43.062 164.554 43.816 164.554 44.598C164.554 45.378 164.358 46.124 163.962 46.837C163.564 47.552 163.005 48.11 162.29 48.507C161.574 48.907 160.824 49.105 160.046 49.105C159.265 49.105 158.519 48.907 157.8 48.507C157.084 48.111 156.526 47.553 156.127 46.837C155.729 46.124 155.528 45.378 155.528 44.598C155.528 43.816 155.731 43.062 156.138 42.338C156.543 41.614 157.109 41.059 157.828 40.669C158.548 40.279 159.285 40.085 160.045 40.085ZM160.045 39.189C160.952 39.189 161.841 39.421 162.704 39.888C163.572 40.354 164.244 41.02 164.727 41.887C165.209 42.754 165.452 43.658 165.452 44.598C165.452 45.53 165.214 46.426 164.738 47.285C164.261 48.144 163.596 48.812 162.736 49.289C161.879 49.764 160.982 50 160.045 50C159.108 50 158.209 49.764 157.352 49.289C156.494 48.812 155.827 48.144 155.349 47.285C154.872 46.426 154.63 45.53 154.63 44.598C154.63 43.657 154.874 42.753 155.36 41.887C155.844 41.02 156.519 40.354 157.385 39.888C158.25 39.422 159.136 39.189 160.045 39.189Z"
                fill="#010101"
            />
        </svg>
    );
};

export default Logo;
