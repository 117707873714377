import { Carousel } from 'nuka-carousel';
import React, { useEffect, useState } from "react";
import Collection from "../components/Collection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Medias from "../components/Medias";
import News from "../components/News";
import Organize from "../components/Organize";
import Services from "../components/Services";
import Shortcuts from "../components/Shortcuts";
import SiteMap from "../components/SiteMap";
import axios from "axios";

const Home = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [banners, setBanners] = useState([]);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        const loadBanners = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/banners?limit=4`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            setBanners(resp.data);
        };

        loadBanners();
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Carousel
                autoplay
                showArrows wrapMode="wrap"
                slideWidth="auto"
            >
                {banners.map((item) =>
                    item.link && item.link !== "" ? (
                        <a href={item.link} rel="noreferrer" target={"_blank"} aria-label={`redirecionamento para o banner ${item.description}`} key={item._id}>
                            <img
                                alt={item.description}
                                style={{
                                    width: '100vw'
                                }}
                                src={
                                    isMobile
                                        ? item.imageMobile
                                            ? item.imageMobile
                                            : item.image
                                        : item.image
                                }
                            />
                        </a>
                    ) : (
                        <img
                            alt={item.description}
                            style={{
                                width: '100vw'
                            }}
                            src={
                                isMobile
                                    ? item.imageMobile
                                        ? item.imageMobile
                                        : item.image
                                    : item.image
                            }
                        />
                    )
                )}
            </Carousel>
            <Shortcuts isMobile={isMobile} />
            <Services />
            <Organize isMobile={isMobile} />
            <News />
            <Medias isMobile={isMobile} />
            <Collection />
            <Footer />
            <SiteMap />
        </div>
    );
};

export default Home;
