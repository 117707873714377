import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField } from "@mui/material";

function ProfissionalData({ isEditing }) {
    return (
        <Grid
            backgroundColor={!isEditing ? "#FFFFFF" : "#EAECF0"}
            borderRadius={2}
            paddingBottom={8}
            marginTop={4}
            paddingX={isEditing ? 4 : 48}
            gap={2}
            rowGap={2}
            container
        >
            <Grid xs={12} md={11.12} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"Secretaria"}
                    variant="outlined"
                    type="text"
                    name="secretary"
                    aria-label="secretary"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Unidade de trabalho"}
                    variant="outlined"
                    type="text"
                    name="workLocal"
                    aria-label="workLocal"
                    fullWidth
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Cargo/Função"}
                    variant="outlined"
                    type="text"
                    name="function"
                    aria-label="function"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Salário base ou Subsídio"}
                    variant="outlined"
                    type="number"
                    name="salary"
                    aria-label="salary"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Data de admissão"}
                    variant="outlined"
                    type="date"
                    name="admissionDate"
                    aria-label="admissionDate"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={11.12} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"Regime de Trabalho"}
                    variant="outlined"
                    type="text"
                    name="work"
                    aria-label="work"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Solte sua imagem aqui ou faça o upload"}
                    type="image"
                    name="salary"
                    aria-label="salary"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Data de admissão"}
                    variant="outlined"
                    type="date"
                    name="admissionDate"
                    aria-label="admissionDate"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}></Grid>
            <Grid xs={12} md={5.5}>
                <Button
                    fullWidth
                    style={{
                        borderRadius: 32,
                        marginTop: 32,
                        textAlign: "center",
                        backgroundColor: "#EB2227",
                    }}
                >
                    <Typography
                        color="#FFFFFF"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                        padding={2}
                    >
                        CONTINUAR
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

export default ProfissionalData;
