const sindicateCourses = () => {
    return (
        <svg
            width="27"
            height="24"
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.88136 17.8983H6.50847V19.5254H3.25424V3.25424H1.62712V21.1525H6.50847V22.7797H0V1.62712H3.25424V0H8.13559C8.88136 0 9.59746 0.114407 10.2839 0.34322C10.9703 0.572034 11.6102 0.915254 12.2034 1.37288C12.7881 0.915254 13.4237 0.572034 14.1102 0.34322C14.7966 0.114407 15.5169 0 16.2712 0H21.1525V1.62712H24.4068V12.0381L22.7797 11.3008V3.25424H21.1525V10.5636L19.5254 9.81356V1.62712H16.2712C15.6695 1.62712 15.0932 1.72881 14.5424 1.9322C13.9915 2.13559 13.4831 2.43644 13.0169 2.83475V10.5636L11.3898 11.3008V2.83475C10.9322 2.44492 10.428 2.1483 9.87712 1.94492C9.32627 1.74153 8.74576 1.63559 8.13559 1.62712H4.88136V17.8983ZM22.7797 21.1398L17.0847 24L11.3898 21.1398C11.3898 20.8347 11.3941 20.5127 11.4025 20.1737C11.411 19.8347 11.4449 19.4915 11.5042 19.1441C11.5636 18.7966 11.6483 18.4703 11.7585 18.1653C11.8686 17.8602 12.0212 17.5763 12.2161 17.3136L9.76271 16.1949V23.5932H8.13559V15.4576L17.0847 11.3898L26.0339 15.4576L21.9534 17.3136L22.0551 17.4534C22.2331 17.7161 22.3686 18 22.4619 18.3051C22.5551 18.6102 22.6271 18.9195 22.678 19.233C22.7288 19.5466 22.7585 19.8686 22.767 20.1992C22.7754 20.5297 22.7797 20.8432 22.7797 21.1398ZM17.0847 13.1695L12.0636 15.4576L17.0847 17.7458L22.1059 15.4576L17.0847 13.1695ZM17.0847 22.1822L21.1525 20.1356C21.1441 19.9153 21.1271 19.7161 21.1017 19.5381C21.0763 19.3602 21.0297 19.1864 20.9619 19.0169C20.8941 18.8475 20.8263 18.6822 20.7585 18.5212C20.6907 18.3602 20.5932 18.1822 20.4661 17.9873L17.0847 19.5254L13.7034 17.9873C13.5763 18.1907 13.4746 18.3729 13.3983 18.5339C13.322 18.6949 13.2542 18.8559 13.1949 19.0169C13.1356 19.178 13.0975 19.3475 13.0805 19.5254C13.0636 19.7034 13.0466 19.911 13.0297 20.1483L17.0847 22.1822Z"
                fill="#EB2227"
            />
        </svg>
    );
};

export default sindicateCourses;
