import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function NewsFullPage({ news, isMobile }) {
    function formatDate(date) {
        const months = [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro",
        ];
        const newDate = new Date(date);

        return `${newDate.getDate()} de ${months[newDate.getMonth()]
            } de ${newDate.getFullYear()}`;
    }

    return (
        <Box marginBottom={2} padding={isMobile ? 4 : 0}>
            <Grid
                container
                display={"flex"}
                mt={2}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid xs={12} md={6}>
                    <Grid container display={"flex"} gap={2} mt={1}>
                        <Grid
                            xs={"auto"}
                            md={"auto"}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "2px 12px",
                                backgroundColor: news.categorieColor,
                                borderRadius: 50,
                            }}
                        >
                            <Typography
                                color="#010101"
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="700"
                                fontSize="10px"
                                lineHeight="14px"
                                letterSpacing={"0.1em"}
                            >
                                {news.categorie}
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <Typography
                                color="#010101"
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="12px"
                                lineHeight="14px"
                            >
                                {formatDate(news.incData)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="37.57px"
                    >
                        {news.titulo}
                    </Typography>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="500"
                        fontSize="18px"
                        lineHeight="24px"
                    >
                        {news.description}
                    </Typography>
                    <div dangerouslySetInnerHTML={{ __html: news.ck }}></div>
                </Grid>
            </Grid>
        </Box>
    );
}

export default NewsFullPage;
