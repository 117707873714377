import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";

function ColonyFullPage({ service }) {
    const [photos, setPhotos] = useState([]);
    const [open, setOpen] = useState(-1);

    const handleClose = () => setOpen(-1);

    useEffect(() => {
        const loadPhotos = async () => {
            let indents = [];
            for (let i = 0; i < service.maximo; i++) {
                indents.push(
                    <img
                        id={i}
                        onClick={(e) => {
                            setOpen(e.currentTarget.id);
                        }}
                        src={`https://servicos-images.s3.sa-east-1.amazonaws.com/${service.ID}/galeria/${i}_max.jpg`}
                        width={"350px"}
                        height={"175px"}
                        alt="imagem de colonias"
                        style={{
                            objectFit: "cover",
                            minWidth: "200px",
                        }}
                    />
                );
            }

            setPhotos(indents);
        };

        const loadPhotosList = async () => {
            let indents = service.photos.map((item, i) => (
                <img
                    id={i}
                    onClick={(e) => {
                        setOpen(e.currentTarget.id);
                    }}
                    src={item}
                    width={"350px"}
                    height={"175px"}
                    alt="imagem de colonias"
                    style={{
                        objectFit: "cover",
                        minWidth: "200px",
                    }}
                />
            ));

            setPhotos(indents);
        };

        if (service.photos && service.photos.length !== 0) {
            loadPhotosList();
        } else {
            loadPhotos();
        }
    }, []);

    return (
        <Box marginBottom={2} p={4}>
            <Modal
                open={open === -1 ? false : true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        p: 4,
                    }}
                >
                    {parseInt(open) !== 0 && (
                        <Typography
                            color="#FFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="48px"
                            lineHeight="37.57px"
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "20px",
                                transform: "translate(-50%, -50%)",
                            }}
                            onClick={() => {
                                const opened = parseInt(open) - 1;
                                if (opened >= 0) {
                                    setOpen(opened);
                                }
                            }}
                        >
                            {`<`}
                        </Typography>
                    )}
                    {parseInt(open) + 1 !== service.maximo && (
                        <Typography
                            color="#FFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="48px"
                            lineHeight="37.57px"
                            style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translate(-50%, -50%)",
                            }}
                            onClick={() => {
                                const opened = parseInt(open) + 1;
                                if (opened < service.maximo) {
                                    setOpen(opened);
                                }
                            }}
                        >
                            {`>`}
                        </Typography>
                    )}
                    <img
                        src={`https://servicos-images.s3.sa-east-1.amazonaws.com/${service.ID}/galeria/${open}_max.jpg`}
                        alt="imagem de colonias"
                        onClick={handleClose}
                        style={{
                            objectFit: "cover",
                            minWidth: "200px",
                            maxWidth: "90vw",
                        }}
                    />
                </Box>
            </Modal>
            <Grid
                container
                display={"flex"}
                mt={2}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Grid xs={12} md={6.1} paddingX={4}>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="37.57px"
                    >
                        {service.titulo}
                    </Typography>
                </Grid>
                <Grid xs={12} md={6} paddingX={4}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: service.ck,
                        }}
                    ></div>
                </Grid>
                <Grid
                    container
                    display={"flex"}
                    mt={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Grid
                        xs={12}
                        md={8}
                        gap={2}
                        marginTop={6}
                        container
                        marginY={6}
                        display={"flex"}
                    >
                        {photos}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

export default ColonyFullPage;
