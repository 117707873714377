import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";

import { Box, Grid, Typography } from "@mui/material";

import SindicateJuridic from "../assets/sindicateJuridic";
import Person from "../assets/person";
import PersonPainted from "../assets/personPainted";
import PersonalData from "../components/PersonalData";
import ProfissionalData from "../components/ProfissionalData";
import AddressData from "../components/AddressData";

const ServerArea = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [selected, setSelected] = useState(0);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);

        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Grid
                backgroundColor="white"
                borderRadius={2}
                paddingX={24}
                marginY={8}
                gap={2}
                rowGap={2}
                container
            >
                <Grid
                    xs={12}
                    md={3.8}
                    backgroundColor={"#EAECF0"}
                    p={4}
                    borderRadius={2}
                >
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="40px"
                        lineHeight="46.96px"
                    >
                        Olá, Nome
                    </Typography>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="20.8px"
                        marginTop={2}
                    >
                        Você está na Área do Servidor, onde poderá ver seus
                        agendamentos, histórico e consultas de documentos. Nosso
                        relacionamento poderá ser feito de maneira fácil e
                        rápida por aqui.
                    </Typography>
                    <Typography
                        color="#EB2227"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                        marginTop={2}
                    >
                        Seu número de cadastro é 1683.
                    </Typography>

                    <div
                        onClick={() => setSelected(0)}
                        style={{ cursor: "pointer" }}
                    >
                        <Box marginTop={2} display={"flex"}>
                            {selected !== 0 && <Person />}
                            {selected === 0 && <PersonPainted />}
                            <Typography
                                color={selected === 0 ? "#EB2227" : "#202020"}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="600"
                                fontSize="18px"
                                lineHeight="18px"
                                marginLeft={1}
                            >
                                Área do Servidor
                            </Typography>
                        </Box>
                    </div>
                    <div
                        onClick={() => setSelected(1)}
                        style={{ cursor: "pointer" }}
                    >
                        <Box marginTop={2} display={"flex"}>
                            {selected !== 1 && <Person />}
                            {selected === 1 && <PersonPainted />}
                            <Typography
                                color={selected === 1 ? "#EB2227" : "#202020"}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="600"
                                fontSize="18px"
                                lineHeight="18px"
                                marginLeft={1}
                            >
                                Dados Pessoais
                            </Typography>
                        </Box>
                    </div>
                    <div
                        onClick={() => setSelected(2)}
                        style={{ cursor: "pointer" }}
                    >
                        <Box marginTop={2} display={"flex"}>
                            {selected !== 2 && <Person />}
                            {selected === 2 && <PersonPainted />}
                            <Typography
                                color={selected === 2 ? "#EB2227" : "#202020"}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="600"
                                fontSize="18px"
                                lineHeight="18px"
                                marginLeft={1}
                            >
                                Dados Profissionais
                            </Typography>
                        </Box>
                    </div>
                    <div
                        onClick={() => setSelected(3)}
                        style={{ cursor: "pointer" }}
                    >
                        <Box marginTop={2} display={"flex"}>
                            {selected !== 3 && <Person />}
                            {selected === 3 && <PersonPainted />}
                            <Typography
                                color={selected === 3 ? "#EB2227" : "#202020"}
                                fontFamily="Raleway"
                                fontStyle="normal"
                                fontWeight="600"
                                fontSize="18px"
                                lineHeight="18px"
                                marginLeft={1}
                            >
                                Endereço
                            </Typography>
                        </Box>
                    </div>
                </Grid>
                {selected === 1 && (
                    <Grid
                        xs={12}
                        md={7.8}
                        backgroundColor={"#EAECF0"}
                        p={4}
                        borderRadius={2}
                    >
                        <PersonalData isEditing={true}  />
                    </Grid>
                )}
                {selected === 2 && (
                    <Grid
                        xs={12}
                        md={7.8}
                        backgroundColor={"#EAECF0"}
                        p={4}
                        borderRadius={2}
                    >
                        <ProfissionalData isEditing={true}  />
                    </Grid>
                )}
                {selected === 3 && (
                    <Grid
                        xs={12}
                        md={7.8}
                        backgroundColor={"#EAECF0"}
                        p={4}
                        borderRadius={2}
                    >
                        <AddressData isEditing={true} />
                    </Grid>
                )}
                {selected === 0 && (
                    <Grid
                        xs={12}
                        md={7.8}
                        backgroundColor={"#EAECF0"}
                        p={4}
                        borderRadius={2}
                    >
                        <Typography
                            color="#202020"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="24px"
                            lineHeight="28.18px"
                        >
                            Como podemos te ajudar?
                        </Typography>
                        <Grid
                            borderRadius={2}
                            paddingX={2}
                            marginY={4}
                            gap={2}
                            rowGap={2}
                            container
                        >
                            <Grid
                                xs={12}
                                md={2.8}
                                backgroundColor="white"
                                p={4}
                                borderRadius={2}
                            >
                                <SindicateJuridic />
                                <Typography
                                    color="#EB2227"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize="18px"
                                    lineHeight="21.13px"
                                    marginTop={1}
                                >
                                    Jurídico
                                </Typography>
                                <Typography
                                    color="#202020"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="14px"
                                    lineHeight="16px"
                                    marginTop={2}
                                >
                                    Agendamento
                                </Typography>
                                <Typography
                                    color="#202020"
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="400"
                                    fontSize="14px"
                                    lineHeight="16px"
                                    marginTop={1}
                                >
                                    Consultar processos
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Footer />
            <SiteMap  />
        </div>
    );
};

export default ServerArea;
