import { Grid2, MenuItem, Pagination, PaginationItem, Select, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import GeneralItem from "./commons/GeneralItem";

const dates = [
    { id: 0, name: "Em qualquer data" },
    { id: 1, name: "Últimas 24 horas" },
    { id: 7, name: "Na última semana" },
    { id: 30, name: "No último mês" },
    { id: 365, name: "No último ano" },
];

function Search({ isMobile }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState([]);
    const [type, setType] = useState(0);
    const [date, setDate] = useState(0);

    useEffect(() => {
        setSearchParams({
            page: Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1,
            search: searchParams.get("search") ? searchParams.get("search") : 1,
            categorie: searchParams.get("categorie") ? searchParams.get("categorie") : 0,
        });
        loadPage(Number(searchParams.get("page")), searchParams.get("search") !== null ? searchParams.get("search") : "", searchParams.get("categorie") ? searchParams.get("categorie") : 0)
    }, [searchParams, type]);

    async function loadPage(number, search, categorie) {
        setSearchParams({ page: number, search, categorie });
        if (number <= 0) {
            setSearchParams({ page: 1, search, categorie });
            number = 1
        }

        const loadData = async (number, search, categorie) => {

            const loadedData = []

            if (type === 0 || type === 1) {
                const resp = await axios.get(
                    `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/news?title=${search ? search : ""
                    }${categorie && categorie !== "0" ? `&categorie=${categorie}` : ""}&limit=20&page=${Number(number) - 1}`,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                        },
                        crossDomain: true,
                    }
                );

                let typefication = resp.data
                typefication = typefication.map(item => ({ ...item, typeSearch: "news" }))

                loadedData.push(...typefication)
            }

            if (type === 0 || type === 3) {
                const resp = await axios.get(
                    `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/materials?title=${search ? search : ""
                    }&page=${Number(number) - 1}&limit=20${categorie && categorie !== "0" ? `&categorie=${categorie}` : ""}`,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                        },
                        crossDomain: true,
                    }
                );

                let typefication = resp.data
                typefication = typefication.map(item => ({ ...item, typeSearch: "materials" }))

                loadedData.push(...typefication)
            }

            if (((type === 0 && search === "") || type === 2) ) {
                const resp = await axios.get(
                    `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/gallery?page=${Number(number) - 1}`,
                    {
                        headers: {
                            "Content-Type": "application/json;charset=utf-8",
                        },
                        crossDomain: true,
                    }
                );

                let typefication = resp.data
                typefication = typefication.map(item => ({ ...item, typeSearch: "gallery" }))

                loadedData.push(...typefication)
            }

            loadedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setData(loadedData);
        };

        loadData(number, search, categorie);

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
    }

    return (
        <Fragment>
            <Grid2
                container
                marginY={6}
                gap={2}
                display={"flex"}
                paddingX={isMobile ? 2 : 8}
            >
                <Grid2
                    xs={8}
                    md={8}
                    style={{
                        color: "#010101",
                        display: 'flex',
                        gap: 16
                    }}
                >
                    <Typography
                        color={type === 0 ? "#EB2227" : "#010101"}
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={(() => setType(0))}
                    >
                        Todos
                    </Typography>
                    <Typography
                        color={type === 1 ? "#EB2227" : "#010101"}
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={(() => setType(1))}
                    >
                        Notícias
                    </Typography>
                    <Typography
                        color={type === 2 ? "#EB2227" : "#010101"}
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={(() => setType(2))}
                    >
                        Galeria
                    </Typography>
                    <Typography
                        color={type === 3 ? "#EB2227" : "#010101"}
                        width={"fit-content"}
                        borderBottom={1}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="800"
                        fontSize="20px"
                        lineHeight="23px"
                        style={{
                            cursor: "pointer"
                        }}
                        onClick={(() => setType(3))}
                    >
                        Materiais
                    </Typography>
                </Grid2>
                <Grid2 xs={3} md={3}>
                    <Select
                        id="demo-select-small"
                        value={date}
                        label="Categorias"
                        variant="standard"
                        style={{
                            color: "#010101",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "500",
                            fontSize: "16px",
                            lineHeight: "23px",
                            textDecoration: "none",
                        }}
                        onChange={(e) =>
                            setDate(e.target.value)
                        }
                    >
                        {dates.map((cat, count) => (
                            <MenuItem key={count} value={cat.id}>
                                {cat.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid2>
                <Grid2 xs={12} md={12} display={"flex"} padding={"0px 10px"}>
                    <Grid2 container marginY={6} gap={4} display={"flex"}>
                        {data.map((item) => (
                            <Grid2
                                xs={12}
                                md={2.4}
                                xl={2.6}
                                display={"flex"}
                                padding={"0px 10px"}
                                key={item._id}
                            >
                                <GeneralItem
                                    image={item.capa ? (item.typeSearch === "materials" ? (item.capa.includes("aws")
                                        ? item.capa
                                        : `https://materiais-bucket.s3.sa-east-1.amazonaws.com/${item.ID}/capa/${item.capa}`) :
                                        item.capa)
                                        : `https://galeria-images.s3.sa-east-1.amazonaws.com/${item.ID}/galeria/0_max.jpeg`}

                                    date={item.incData ? item.incData : item.createdAt}
                                    description={item.titulo}
                                    link={item?.maximo ? `/galeria/fotos?link=${item.link}` :
                                        item?.arquivo ? `/materiais/item?link=${item.link}` : `/noticia?link=${item.link}`}
                                    categorie={item.categorie}
                                    categorieColor={item.categorieColor}
                                    isMobile={isMobile}
                                />
                            </Grid2>
                        ))}
                    </Grid2>
                </Grid2>
            </Grid2>
            <Grid2
                xs={12}
                md={12}
                padding={"16px 0px"}
                color="#FFFFFF"
                borderBottom={1}
                display={"flex"}
                justifyContent={"center"}
            >
                <Pagination
                    color="warning"
                    variant="outlined" count={50} page={Number(searchParams.get("page"))} onChange={(e, number) => loadPage(number, searchParams.get("search"))}
                    renderItem={(item) => (
                        <PaginationItem
                            style={{
                                borderRadius: 100,
                                color: "#EB2227",
                                padding: "8px 16px",
                                fontFamily: "Raleway",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "20px",
                            }}

                            slots={{ previous: ArrowBackOutlined, next: ArrowForwardOutlined }}
                            {...item}
                        />)}
                />
            </Grid2>
        </Fragment>
    );
}

export default Search;
