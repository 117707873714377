import React, { useEffect, useState } from "react";
import NewsFullPage from "../components/commons/NewsFullPage";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import axios from "axios";
import { useParams } from "react-router-dom";

const NewsItemLink = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [news, setNews] = useState(null);
    const { link, title } = useParams();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadNews = async () => {
            const newsData = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/links?link=/noticias/${link}/${title}/`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );
            
            setNews(newsData.data[0]);
        };

        loadNews();

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            {news && <NewsFullPage news={news} isMobile={isMobile} />}
            <Footer />
            <SiteMap />
        </div>
    );
};

export default NewsItemLink;
