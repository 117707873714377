const Accessible = () => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14.7999 8.62875V7.25732H5.19995V8.62875H8.62852V10.5035L5.97618 15.1458L7.16658 15.826L9.71195 11.3716H10.288L12.8333 15.826L14.0237 15.1458L11.3714 10.5035V8.62875H14.7999Z"
                fill="white"
            />
            <path
                d="M10.0001 6.57147C10.7575 6.57147 11.3715 5.95746 11.3715 5.20004C11.3715 4.44262 10.7575 3.82861 10.0001 3.82861C9.24267 3.82861 8.62866 4.44262 8.62866 5.20004C8.62866 5.95746 9.24267 6.57147 10.0001 6.57147Z"
                fill="white"
            />
            <path
                d="M9.99991 19.5999C8.1012 19.5999 6.24514 19.0369 4.66643 17.982C3.08772 16.9272 1.85726 15.4278 1.13066 13.6737C0.404062 11.9195 0.21395 9.98926 0.584368 8.12704C0.954786 6.26482 1.8691 4.55427 3.21168 3.21168C4.55427 1.8691 6.26482 0.954786 8.12704 0.584368C9.98926 0.21395 11.9195 0.404062 13.6737 1.13066C15.4278 1.85726 16.9272 3.08772 17.982 4.66643C19.0369 6.24514 19.5999 8.1012 19.5999 9.99991C19.597 12.5451 18.5846 14.9852 16.7849 16.7849C14.9852 18.5846 12.5451 19.597 9.99991 19.5999ZM9.99991 1.77134C8.37245 1.77134 6.78154 2.25393 5.42836 3.1581C4.07518 4.06227 3.0205 5.34739 2.3977 6.85097C1.7749 8.35454 1.61194 10.009 1.92944 11.6052C2.24695 13.2014 3.03064 14.6676 4.18143 15.8184C5.33221 16.9692 6.79841 17.7529 8.39459 18.0704C9.99078 18.3879 11.6453 18.2249 13.1488 17.6021C14.6524 16.9793 15.9375 15.9246 16.8417 14.5715C17.7459 13.2183 18.2285 11.6274 18.2285 9.99991C18.2259 7.81833 17.3582 5.72684 15.8156 4.18423C14.273 2.64163 12.1815 1.77388 9.99991 1.77134Z"
                fill="white"
            />
        </svg>
    );
};

export default Accessible;
