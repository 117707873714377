import { ArrowRightAlt } from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import React, { Fragment } from "react";
import BlackCalculator from "../assets/blackcalculator";
import Calculator from "../assets/calculator";
import City from "../assets/city";
import LegalBlack from "../assets/legalBlack";
import Medical from "../assets/medical";
import NewsPaper from "../assets/newspaper";
import Odonto from "../assets/odonto";
import Presentation from "../assets/presentation";
import Room from "../assets/room";
import Table from "../assets/table";
import Vacations from "../assets/vacations";
import Warning from "../assets/warning";
import { Link } from "react-router-dom";

function Shortcuts({ isMobile }) {
    return (
        <Fragment>
            {/*
            <Grid
                container
                marginTop={6}
                gap={4}
                display={"flex"}
                justifyContent={"center"}
                paddingX={2}
            >
                <Grid
                    xs={5.5}
                    md={3.5}
                    backgroundColor={"#202020"}
                    borderRadius={"12px"}
                >
                    <a
                        aria-label="redirecionamento para o whatsapp da central de denuncias"
                        href="https://api.whatsapp.com/send?phone=5511970255497"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px 34px"}
                                display={"flex"}
                                alignItems={"center"}
                                width={isMobile ? "80%" : "85%"}
                                gap={2}
                            >
                                <Room />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="800"
                                    fontSize="20px"
                                    lineHeight="23px"
                                    color="#202020"
                                >
                                    CENTRAL DE DENÚNCIAS DO SERVIDOR
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 100,
                                    color: "#202020",
                                    marginTop: 16,
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                FALE CONOSCO
                            </Button>
                        </Box>
                    </a>
                </Grid>
                <Grid
                    xs={5.5}
                    md={3.5}
                    backgroundColor={"#202020"}
                    borderRadius={"12px"}
                >
                    <a
                        aria-label="redirecionamento para tabela de vencimentos do funcionalismo"
                        href="https://infosind.com.br/Cobranca/Convenio/Login?ewid=55955464"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px 34px"}
                                display={"flex"}
                                alignItems={"center"}
                                width={isMobile ? "80%" : "85%"}
                                gap={2}
                            >
                                <Calculator />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="800"
                                    fontSize="20px"
                                    lineHeight="23px"
                                    color="#202020"
                                >
                                    BOLETO ONLINE DO SINDSEP
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 100,
                                    color: "#202020",
                                    marginTop: 16,
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                BAIXE AQUI
                            </Button>
                        </Box>
                    </a>
                </Grid>

                <Grid
                    xs={5.5}
                    md={3.5}
                    backgroundColor={"#202020"}
                    borderRadius={"12px"}
                >
                    <Link
                        to="/servicos"
                        aria-label="link para serviços"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px 34px"}
                                display={"flex"}
                                alignItems={"center"}
                                width={isMobile ? "80%" : "85%"}
                                gap={2}
                            >
                                <City />
                                <Typography
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="800"
                                    fontSize="20px"
                                    lineHeight="23px"
                                    color="#202020"
                                >
                                    CENTRAL DE BENEFÍCIOS E SERVIÇOS
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 100,
                                    color: "#202020",
                                    marginTop: 16,
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "16px",
                                    lineHeight: "20px",
                                }}
                            >
                                NOSSOS CONVÊNIOS
                            </Button>
                        </Box>
                    </Link>
                </Grid>
            </Grid>*/}
            <Link
                to="/noticia?link=/noticias/juridico/golpe-dos-precatorios-5245/"
                aria-label="link para juridico"
                style={{
                    textDecoration: "none",
                }}
            >
                <Box
                    backgroundColor={"#EB2227"}
                    padding={"12px 0px"}
                    borderRadius={"12px"}
                    marginTop={4}
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Warning />
                    <Typography
                        marginX={4}
                        color="#FFFFFF"
                        whiteSpace={"pre-line"}
                        textAlign="center"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="26px"
                        lineHeight="30px"
                    >
                        {`CUIDADO COM
                     GOLPES DOS PRECATÓRIOS`}
                    </Typography>
                    <Button
                        size="small"
                        style={{
                            backgroundColor: "#202020",
                            borderRadius: 100,
                            color: "#FFFFFF",
                            padding: "8px 16px",
                            fontFamily: "Raleway",
                            fontStyle: "normal",
                            fontWeight: "600",
                            fontSize: "16px",
                            lineHeight: "20px",
                        }}
                    >
                        VEJA MAIS INFORMAÇÕES
                    </Button>
                </Box>
            </Link>
            <Grid
                container
                marginTop={6}
                gap={2}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Grid xs={12} md={2.5}>
                    <Typography
                        color="#202020"
                        whiteSpace={"pre-line"}
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38px"
                        textAlign={isMobile ? "center" : "left"}
                    >
                        {`ACESSO RÁPIDO
                    PARA SERVIDORES`}
                    </Typography>
                </Grid>
                <Grid
                    xs={5.5}
                    md={2}
                    borderRadius={"12px"}
                    textAlign={isMobile ? "center" : "left"}
                >
                    <Link
                        to="/juridico"
                        aria-label="link para juridico"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <LegalBlack />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`JURÍDICO`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                ACESSE AQUI
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                {/* att
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    
                    <Link
                        to="/atualize-seu-cadastro"
                        aria-label="link para atualização cadastral"
                        style={{
                            color: "#202020",
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Medical />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`ATUALIZE SEU 
                                    CADASTRO`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                ATUALIZE AGORA
                            </Button>
                        </Box>
                    </Link>
                </Grid>*/}
                 
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        to="/servicos?benefits=Colônias de Férias"
                        aria-label="link para noticias"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Vacations />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`COLÔNIA
                                        DE FÉRIAS`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                RESERVE AGORA
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        aria-label="denuncie assedio moral"
                        to="/noticia?link=/noticias/saude-do-trabalhador/assedio-moral-o-que-e-e-como-denunciar-6772/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Presentation />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`ASSÉDIO MORAL:
                                    O que é e como denunciar`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                DENUNCIE AQUI
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        aria-label="denuncie assedio moral"
                        to="/noticia?link=/noticias/noticias/clipping-de-imprensa-5412/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <NewsPaper />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`CLIPPING DE
                                        IMPRENSA`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                SAIU NA IMPRENSA
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={0} md={2.5} borderRadius={"12px"}></Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <a
                        aria-label="redirecionamento para plano odontologico"
                        href="https://www.solidente.com.br/sindsep/ "
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Odonto />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`PLANO
                                    ODONTOLÓGICO `}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                CONSULTE AGORA
                            </Button>
                        </Box>
                    </a>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        aria-label="denuncie assedio moral"
                        to="noticia?link=tabela-remuneratoria-das-carreiras-do-spmsp---2024"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Table />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`TABELAS REMUNERATÓRIAS`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                CONSULTE AGORA
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        aria-label="calculadora de servidores"
                        to="/calculadora"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <BlackCalculator />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`CALCULADORA DE SERVIDORES`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                CALCULE AQUI
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <a
                        aria-label="central de denuncias"
                        href="https://api.whatsapp.com/send?phone=5511970255497"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Presentation />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`CENTRAL DE DENÚNCIAS DO SERVIDOR`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                FALE CONOSCO
                            </Button>
                        </Box>
                    </a>
                </Grid>
                <Grid xs={0} md={2.5} borderRadius={"12px"}></Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <a
                        aria-label="boleto online sindsep"
                        href="https://infosind.com.br/Cobranca/Convenio/Login?ewid=55955464"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <NewsPaper />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`BOLETO ONLINE DO SINDSEP`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                BAIXE AQUI
                            </Button>
                        </Box>
                    </a>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <Link
                        aria-label="central de beneficios"
                        to="/servicos"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Vacations />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`CENTRAL DE BENEFÍCIOS E SERVIÇOS`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                NOSSOS CONVÊNIOS
                            </Button>
                        </Box>
                    </Link>
                </Grid>
                <Grid xs={5.5} md={2} borderRadius={"12px"}>
                    <a
                        aria-label="faça sua filiação"
                        href="https://sindicalizacao.sindsep-sp.org.br"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            display={"flex"}
                            flexDirection={"column"}
                            justifyContent={"center"}
                            padding={"14px"}
                        >
                            <Box
                                backgroundColor={"#FFFFFF"}
                                borderRadius={"12px"}
                                padding={"16px"}
                                display={"flex"}
                                alignItems={"center"}
                                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.15), 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px -8px 12px 0px rgba(16, 24, 40, 0.07)"
                                gap={2}
                            >
                                <Medical />
                                <Typography
                                    color="#202020"
                                    whiteSpace={"pre-line"}
                                    fontFamily="Raleway"
                                    fontStyle="normal"
                                    fontWeight="700"
                                    fontSize={ !isMobile ? "15px" : "13px"}
                                    lineHeight="120%"
                                    maxWidth={"75%"}
                                >
                                    {`FAÇA SUA  FILIAÇÃO E PARTICIPE`}
                                </Typography>
                            </Box>
                            <Button
                                size="small"
                                endIcon={<ArrowRightAlt />}
                                style={{
                                    backgroundColor: "#202020",
                                    borderRadius: 100,
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    color: "#FFFFFF",
                                    padding: "8px 16px",
                                    fontFamily: "Raleway",
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize:  !isMobile ? "14px" : "11px",
                                    lineHeight: "22px",
                                }}
                            >
                                SEJÁ SÓCIO
                            </Button>
                        </Box>
                    </a>
                </Grid>
            </Grid>
        </Fragment>
    );
}

export default Shortcuts;
