const School = () => {
    return (
        <svg
            width="139"
            height="128"
            viewBox="0 0 139 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.0339 95.4576H34.7119V104.136H17.3559V17.3559H8.67797V112.814H34.7119V121.492H0V8.67797H17.3559V0H43.3898C47.3672 0 51.1864 0.610169 54.8475 1.83051C58.5085 3.05085 61.9209 4.88136 65.0847 7.32203C68.2034 4.88136 71.5932 3.05085 75.2542 1.83051C78.9153 0.610169 82.7571 0 86.7797 0H112.814V8.67797H130.169V64.2034L121.492 60.2712V17.3559H112.814V56.339L104.136 52.339V8.67797H86.7797C83.5706 8.67797 80.4972 9.22034 77.5593 10.3051C74.6215 11.3898 71.9096 12.9944 69.4237 15.1186V56.339L60.7458 60.2712V15.1186C58.3051 13.0395 55.6158 11.4576 52.678 10.3729C49.7401 9.28814 46.6441 8.72316 43.3898 8.67797H26.0339V95.4576ZM121.492 112.746L91.1186 128L60.7458 112.746C60.7458 111.119 60.7684 109.401 60.8136 107.593C60.8588 105.785 61.0395 103.955 61.3559 102.102C61.6723 100.249 62.1243 98.5085 62.7119 96.8814C63.2994 95.2542 64.113 93.7401 65.1525 92.339L52.0678 86.3729V125.831H43.3898V82.4407L91.1186 60.7458L138.847 82.4407L117.085 92.339L117.627 93.0847C118.576 94.4859 119.299 96 119.797 97.6271C120.294 99.2542 120.678 100.904 120.949 102.576C121.22 104.249 121.379 105.966 121.424 107.729C121.469 109.492 121.492 111.164 121.492 112.746ZM91.1186 70.2373L64.339 82.4407L91.1186 94.6441L117.898 82.4407L91.1186 70.2373ZM91.1186 118.305L112.814 107.39C112.768 106.215 112.678 105.153 112.542 104.203C112.407 103.254 112.158 102.328 111.797 101.424C111.435 100.52 111.073 99.6384 110.712 98.7797C110.35 97.9209 109.831 96.9717 109.153 95.9322L91.1186 104.136L73.0847 95.9322C72.4068 97.017 71.8644 97.9887 71.4576 98.8475C71.0508 99.7062 70.6893 100.565 70.3729 101.424C70.0565 102.282 69.8531 103.186 69.7627 104.136C69.6723 105.085 69.5819 106.192 69.4915 107.458L91.1186 118.305Z"
                fill="white"
            />
        </svg>
    );
};

export default School;
