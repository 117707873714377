const News = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 8H26V16H4V8ZM4 4H26V6H4V4ZM16 26H22V28H16V26ZM16 22H26V24H16V22ZM16 18H26V20H16V18ZM4 26H14V28H4V26ZM4 22H14V24H4V22ZM4 18H14V20H4V18Z"
                fill="#202020"
            />
            <path
                d="M30 4V0H0V29C0 29.7956 0.31607 30.5587 0.87868 31.1213C1.44129 31.6839 2.20435 32 3 32H29C29.7956 32 30.5587 31.6839 31.1213 31.1213C31.6839 30.5587 32 29.7956 32 29V4H30ZM3 30C2.73478 30 2.48043 29.8946 2.29289 29.7071C2.10536 29.5196 2 29.2652 2 29V2H28V27C28 30 30 30 30 30H3Z"
                fill="#202020"
            />
        </svg>
    );
};

export default News;
