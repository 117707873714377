const Lib = () => {
    return (
        <svg
            width="139"
            height="128"
            viewBox="0 0 87 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.6704 80H2.67044C2.10212 80 1.55708 79.7742 1.15522 79.3724C0.753353 78.9705 0.527588 78.4255 0.527588 77.8571V10.7143C0.527588 10.146 0.753353 9.60092 1.15522 9.19906C1.55708 8.79719 2.10212 8.57143 2.67044 8.57143H12.6704C13.2388 8.57143 13.7838 8.79719 14.1857 9.19906C14.5875 9.60092 14.8133 10.146 14.8133 10.7143V77.8571C14.8133 78.4255 14.5875 78.9705 14.1857 79.3724C13.7838 79.7742 13.2388 80 12.6704 80ZM40.5276 31.4286V22.1429C40.5276 21.5745 40.3018 21.0295 39.9 20.6276C39.4981 20.2258 38.9531 20 38.3847 20H19.8133C19.245 20 18.6999 20.2258 18.2981 20.6276C17.8962 21.0295 17.6704 21.5745 17.6704 22.1429V31.4286H40.5276ZM17.6704 68.5714V77.8571C17.6704 78.4255 17.8962 78.9705 18.2981 79.3724C18.6999 79.7742 19.245 80 19.8133 80H38.3847C38.9531 80 39.4981 79.7742 39.9 79.3724C40.3018 78.9705 40.5276 78.4255 40.5276 77.8571V68.5714H17.6704ZM17.6704 37.1429H40.5276V62.8571H17.6704V37.1429ZM58.3847 80H45.5276C44.9593 80 44.4142 79.7742 44.0124 79.3724C43.6105 78.9705 43.3847 78.4255 43.3847 77.8571V2.14286C43.3847 1.57454 43.6105 1.02949 44.0124 0.627628C44.4142 0.225764 44.9593 0 45.5276 0H58.3847C58.9531 0 59.4981 0.225764 59.9 0.627628C60.3018 1.02949 60.5276 1.57454 60.5276 2.14286V77.8571C60.5276 78.4255 60.3018 78.9705 59.9 79.3724C59.4981 79.7742 58.9531 80 58.3847 80ZM63.5633 12.2679L68.9204 77.9518C68.9442 78.2398 69.026 78.5201 69.1609 78.7757C69.2958 79.0313 69.481 79.257 69.7054 79.4391C69.9298 79.6213 70.1887 79.7562 70.4665 79.8357C70.7444 79.9152 71.0355 79.9377 71.3222 79.9018L84.2079 78.2946C84.7566 78.226 85.2575 77.948 85.6059 77.5187C85.9543 77.0894 86.1233 76.5421 86.0776 75.9911L80.7062 10.5732C80.6825 10.2891 80.6024 10.0125 80.4705 9.75976C80.3386 9.50698 80.1576 9.28307 79.9381 9.10113C79.7186 8.9192 79.4649 8.7829 79.1921 8.70022C78.9192 8.61754 78.6326 8.59015 78.349 8.61964L65.4687 9.96071C64.9129 10.0208 64.4026 10.2956 64.0466 10.7267C63.6906 11.1577 63.5172 11.7107 63.5633 12.2679Z"
                fill="white"
            />
        </svg>
    );
};

export default Lib;
