import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import axios from "axios";
import { useParams } from "react-router-dom";
import MaterialFullPage from "../components/commons/MaterialFullPage";

const MaterialItemLink = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [material, setMaterial] = useState(null);
    const { link, title } = useParams();

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        const loadMaterial = async () => {
            const materialsData = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/materials/links/find?link=/materiais/${link}/${title}/`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );
            
            setMaterial(materialsData.data[0]);
        };

        loadMaterial();

        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            {material && <MaterialFullPage material={material} />}
            <Footer />
            <SiteMap />
        </div>
    );
};

export default MaterialItemLink;
