import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField } from "@mui/material";

function PersonalData({ isEditing }) {
    return (
        <Grid
            backgroundColor={!isEditing ? "#FFFFFF" : "#EAECF0"}
            borderRadius={2}
            paddingBottom={8}
            marginTop={4}
            paddingX={isEditing ? 4 : 48}
            gap={2}
            rowGap={2}
            container
        >
            <Grid xs={12} md={11.12} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"Nome/Nome Social"}
                    variant="outlined"
                    type="text"
                    name="name"
                    aria-label="name"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Data de nascimento"}
                    variant="outlined"
                    type="date"
                    name="birthday"
                    aria-label="birthday"
                    fullWidth
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Gênero (Quero me identificar como)"}
                    variant="outlined"
                    type="text"
                    name="genre"
                    aria-label="genre"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"E-mail"}
                    variant="outlined"
                    type="email"
                    name="busca"
                    aria-label="busca"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Celular"}
                    variant="outlined"
                    type="tel"
                    name="phone"
                    aria-label="phone"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"CPF"}
                    variant="outlined"
                    type="number"
                    name="cpf"
                    aria-label="cpf"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"RG"}
                    variant="outlined"
                    type="number"
                    name="rg"
                    aria-label="rg"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Como você define sua cor?"}
                    variant="outlined"
                    type="number"
                    name="ethnicity"
                    aria-label="ethnicity"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Estado civil"}
                    variant="outlined"
                    type="number"
                    name="civilState"
                    aria-label="civilState"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Escolaridade"}
                    variant="outlined"
                    type="number"
                    name="scholarity"
                    aria-label="scholarity"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"RF (Registro Funcional)"}
                    variant="outlined"
                    type="number"
                    name="funtionalRegister"
                    aria-label="funtionalRegister"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={11.12} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"Alguém indicou sua filiação?"}
                    variant="outlined"
                    type="text"
                    name="indication"
                    aria-label="indication"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={11.12} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"Nome de quem te indicou"}
                    variant="outlined"
                    type="text"
                    name="indicationName"
                    aria-label="indicationName"
                    fullWidth
                    defaultValue={""}
                    style={{
                        backgroundColor: isEditing ? "#FFFFFF" : "#EAECF0",
                    }}
                />
            </Grid>
            <Grid xs={12} md={5.5}></Grid>
            <Grid xs={12} md={5.5}>
                <Button
                    fullWidth
                    style={{
                        borderRadius: 32,
                        marginTop: 32,
                        textAlign: "center",
                        backgroundColor: "#EB2227",
                    }}
                >
                    <Typography
                        color="#FFFFFF"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                        padding={2}
                    >
                        CONTINUAR
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

export default PersonalData;
