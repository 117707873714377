const Odonto = () => {
    return (
        <svg
        width="40"
        height="40"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.07 5.03908L28.76 8.68959M22.07 5.03908C15.38 1.40196 2 -1.9475 2 13.7673C2 26.0144 4.64924 35.5231 8.70114 40.8417C10.1395 42.7305 13.6428 42.144 15.1414 40.2507C15.3073 40.0282 15.4381 39.7815 15.5294 39.5193L17.8999 33.2753C19.3583 29.4397 24.7817 29.4397 26.2401 33.2753L28.6106 39.5193C28.7109 39.778 28.8269 40.0322 28.9986 40.2507C30.4971 42.1462 34.0005 42.7305 35.4388 40.8417C39.4907 35.5231 42.14 26.0167 42.14 13.7673C42.14 -1.94527 28.76 1.40196 22.07 5.03908V5.03908Z"
                stroke="#202020"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Odonto;
