import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SiteMap from "../components/SiteMap";
import { Box } from "@mui/material";

const Calculator = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });

        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Header isMobile={isMobile} />
            <Box style={{
                display: 'flex',
                width: '100vw',
                alignItems: 'center',
                justifyContent: 'center',
                height:'90vh'
            }}>
                <iframe
                    src="https://grid.is/embed/calculadora-sindsep-pl-428-2022-8WjY3JdRRJmkaFHGZNw:Mw?scale_to_fit=true&overflow=hidden"
                    width="60%"
                    data-document-id="f168d8dc-9751-4499-a468-51c664dc3e33"
                    style={{ border: 0, height: '100%' }}
                    title="Calculadora"
                />
            </Box>
            <Footer />
            <SiteMap />
        </div>
    );
};

export default Calculator;
