import { useEffect, useState } from "react";
import "./App.css";

import routes from "./pages/index";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import NewsItem from "./pages/NewsItem";
import NewsItemLink from "./pages/NewsItemLink";
import ColonyItemId from "./pages/ColonyItemId";
import MaterialItemLink from "./pages/MaterialItemLink";
import PageId from "./pages/PageId";

function App() {
    const [routesApp, setRoutesApp] = useState([]);

    useEffect(() => {
        setRoutesApp(routes);

        const loadRoutes = async () => {
            const resp = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/news?limit=10`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            const filter = resp.data.map((news) => ({
                path: news.link,
                component: <NewsItem news={news} />,
            }));

            /*const links = await axios.get(
                `https://mw2c0cho61.execute-api.sa-east-1.amazonaws.com/production/links/news?limit=2500`,
                {
                    headers: {
                        "Content-Type": "application/json;charset=utf-8",
                    },
                    crossDomain: true,
                }
            );

            const filterLinks = links.data.map((news) => ({
                path: news.link,
                key: news.link,
                element: (
                    <NewsItemId
                        link={news.link}
                    />
                ),
            }));*/

            const final = routes.concat(filter);

            //const finalLinks = routes.concat(filterLinks);

            setRoutesApp(final);
            //setRoutesApp(finalLinks);
        };

        loadRoutes();
    }, []);

    return (
        <Routes>
            {routesApp.map((data, index) => (
                <Route
                    exact={true}
                    path={data.path}
                    element={data.component}
                    key={index}
                />
            ))}

            <Route path={"noticias/:link/:title"} element={<NewsItemLink />} />
            <Route
                path={"materiais/:link/:title"}
                element={<MaterialItemLink />}
            />
            <Route path={"servicos/:id"} element={<ColonyItemId />} />
            <Route path={":id"} element={<PageId />} />
        </Routes>
    );
}

export default App;
