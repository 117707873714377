import React from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid, TextField } from "@mui/material";

function AddressData({ isEditing }) {
    return (
        <Grid
            backgroundColor={!isEditing ? "#FFFFFF": "#EAECF0"}
            borderRadius={2}
            paddingBottom={8}
            marginTop={4}
            paddingX={isEditing ? 4: 48}
            gap={2}
            rowGap={2}
            container
        >
            <Grid xs={12} md={5.5} marginTop={2}>
                <TextField
                    id="outlined-basic"
                    label={"CEP"}
                    variant="outlined"
                    type="number"
                    name="cep"
                    aria-label="cep"
                    fullWidth
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={11.12}>
                <TextField
                    id="outlined-basic"
                    label={"Endereço"}
                    variant="outlined"
                    type="text"
                    name="address"
                    aria-label="address"
                    fullWidth
                    defaultValue={""}
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Número"}
                    variant="outlined"
                    type="number"
                    name="number"
                    aria-label="number"
                    fullWidth
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Complemento"}
                    variant="outlined"
                    type="text"
                    name="complement"
                    aria-label="complement"
                    fullWidth
                    defaultValue={""}
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={11.12}>
                <TextField
                    id="outlined-basic"
                    label={"Bairro"}
                    variant="outlined"
                    type="text"
                    name="neighboor"
                    aria-label="neighboor"
                    fullWidth
                    defaultValue={""}
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Cidade"}
                    variant="outlined"
                    type="text"
                    name="city"
                    aria-label="city"
                    fullWidth
                    defaultValue={""}
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={5.5}>
                <TextField
                    id="outlined-basic"
                    label={"Estado"}
                    variant="outlined"
                    type="text"
                    name="state"
                    aria-label="state"
                    fullWidth
                    defaultValue={""}
                    style={{ backgroundColor: isEditing ? "#FFFFFF": "#EAECF0" }}
                />
            </Grid>
            <Grid xs={12} md={5.5}></Grid>
            <Grid xs={12} md={5.5}>
                <Button
                    fullWidth
                    style={{
                        borderRadius: 32,
                        marginTop: 32,
                        textAlign: "center",
                        backgroundColor: "#EB2227",
                    }}
                >
                    <Typography
                        color="#FFFFFF"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                        padding={2}
                    >
                        CONTINUAR
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    );
}

export default AddressData;
