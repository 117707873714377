const Library = () => {
    return (
        <svg
            width="31"
            height="28"
            viewBox="0 0 31 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4.77759 28H1.27759C1.07868 28 0.88791 27.921 0.747258 27.7803C0.606606 27.6397 0.527588 27.4489 0.527588 27.25V3.75C0.527588 3.55109 0.606606 3.36032 0.747258 3.21967C0.88791 3.07902 1.07868 3 1.27759 3H4.77759C4.9765 3 5.16727 3.07902 5.30792 3.21967C5.44857 3.36032 5.52759 3.55109 5.52759 3.75V27.25C5.52759 27.4489 5.44857 27.6397 5.30792 27.7803C5.16727 27.921 4.9765 28 4.77759 28ZM14.5276 11V7.75C14.5276 7.55109 14.4486 7.36032 14.3079 7.21967C14.1673 7.07902 13.9765 7 13.7776 7H7.27759C7.07868 7 6.88791 7.07902 6.74726 7.21967C6.60661 7.36032 6.52759 7.55109 6.52759 7.75V11H14.5276ZM6.52759 24V27.25C6.52759 27.4489 6.60661 27.6397 6.74726 27.7803C6.88791 27.921 7.07868 28 7.27759 28H13.7776C13.9765 28 14.1673 27.921 14.3079 27.7803C14.4486 27.6397 14.5276 27.4489 14.5276 27.25V24H6.52759ZM6.52759 13H14.5276V22H6.52759V13ZM20.7776 28H16.2776C16.0787 28 15.8879 27.921 15.7473 27.7803C15.6066 27.6397 15.5276 27.4489 15.5276 27.25V0.75C15.5276 0.551088 15.6066 0.360322 15.7473 0.21967C15.8879 0.0790175 16.0787 0 16.2776 0H20.7776C20.9765 0 21.1673 0.0790175 21.3079 0.21967C21.4486 0.360322 21.5276 0.551088 21.5276 0.75V27.25C21.5276 27.4489 21.4486 27.6397 21.3079 27.7803C21.1673 27.921 20.9765 28 20.7776 28ZM22.5901 4.29375L24.4651 27.2831C24.4734 27.3839 24.502 27.482 24.5492 27.5715C24.5965 27.6609 24.6613 27.7399 24.7398 27.8037C24.8183 27.8675 24.909 27.9147 25.0062 27.9425C25.1035 27.9703 25.2053 27.9782 25.3057 27.9656L29.8157 27.4031C30.0077 27.3791 30.183 27.2818 30.305 27.1316C30.4269 26.9813 30.4861 26.7897 30.4701 26.5969L28.5901 3.70062C28.5818 3.60118 28.5538 3.50439 28.5076 3.41591C28.4614 3.32744 28.3981 3.24907 28.3213 3.1854C28.2444 3.12172 28.1557 3.07401 28.0602 3.04508C27.9647 3.01614 27.8643 3.00655 27.7651 3.01687L23.257 3.48625C23.0624 3.50727 22.8838 3.60347 22.7592 3.75433C22.6347 3.90519 22.574 4.09876 22.5901 4.29375Z"
                fill="#202020"
            />
        </svg>
    );
};

export default Library;
