const Warning = () => {
    return (
        <svg
            width="80"
            height="80"
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.33325 70.0001H76.6666L39.9999 6.66675L3.33325 70.0001ZM43.3333 60.0001H36.6666V53.3334H43.3333V60.0001ZM43.3333 46.6667H36.6666V33.3334H43.3333V46.6667Z"
                fill="white"
            />
        </svg>
    );
};

export default Warning;
