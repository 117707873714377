import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header2 from "../components/Header2";
import SiteMap from "../components/SiteMap";
import back1 from "../assets/back1.svg";
import back2 from "../assets/back2.svg";

import SindicateBenefits from "../assets/sindicateBenefits";
import SindicateCourses from "../assets/sindicateCourses";
import SindicateJuridic from "../assets/sindicateJuridic";
import SindicateVacation from "../assets/sindicateVacation";

import { Box, Button, Grid, TextField, Typography } from "@mui/material";

const Sindicate = () => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        /*window.scrollTo({
            top: 0,
            behavior: "smooth", // for smoothly scrolling
        });
        */
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 768;

    return (
        <div>
            <Box
                style={{
                    backgroundImage: `url(${back1}`,
                    backgroundSize: "cover",
                }}
            >
                <Header2 isMobile={isMobile} />
                <Typography
                    color="#FFFFFF"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="64px"
                    lineHeight="74px"
                    width={"40vw"}
                    paddingLeft={16}
                    paddingTop={16}
                >
                    Faça parte de um sindicato forte.
                </Typography>
                <Typography
                    color="#ACB2BF"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="20px"
                    lineHeight="130%"
                    width={"45vw"}
                    marginTop={4}
                    paddingLeft={16}
                    paddingBottom={16}
                >
                    O Sindsep é o sindicato que representa e organiza os
                    trabalhadores do serviço público municipal e autarquias
                    municipais de São Paulo. Lutamos por valorização, condição
                    de trabalho digna e respeito aos direitos de todos os
                    servidores e servidoras. Se associar ao sindicato é ajudar
                    na construção de uma representação que trabalha para lhe
                    defender ainda mais forte!
                </Typography>
            </Box>
            <Box
                style={{
                    backgroundImage: `url(${back2}`,
                    backgroundSize: "cover",
                }}
            >
                <Typography
                    color="#FFC700"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="40px"
                    lineHeight="46.96px"
                    width={"40vw"}
                    paddingLeft={16}
                    paddingTop={16}
                >
                    Benefícios para o sócio
                </Typography>
                <Typography
                    color="#FFFFFF"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="400"
                    fontSize="20px"
                    lineHeight="26px"
                    width={"30vw"}
                    marginTop={4}
                    paddingLeft={16}
                    paddingBottom={8}
                >
                    Ser sócio é fazer parte desta ferramenta que existe para
                    lutar e defender seus interesses
                </Typography>

                <Grid
                    container
                    marginY={6}
                    xs={12}
                    md={6}
                    gap={4}
                    display={"flex"}
                    paddingX={isMobile ? 2 : 8}
                    paddingLeft={16}
                    paddingBottom={16}
                >
                    <Grid xs={12} md={5.5} marginTop={4}>
                        <Box
                            width={48}
                            height={48}
                            backgroundColor="white"
                            borderRadius={2}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SindicateJuridic />
                        </Box>
                        <Typography
                            color="#FFC700"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            marginTop={2}
                        >
                            Departamento Jurídico
                        </Typography>
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="130%"
                            marginTop={2}
                        >
                            Contamos com uma equipe especializada para prestar
                            apoio jurídico aos servidores, acompanhamento de
                            processos e todo suporte necessário.
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={5.5} marginTop={4}>
                        <Box
                            width={48}
                            height={48}
                            backgroundColor="white"
                            borderRadius={2}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SindicateVacation />
                        </Box>
                        <Typography
                            color="#FFC700"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            marginTop={2}
                        >
                            Colônia de férias
                        </Typography>
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="130%"
                            marginTop={2}
                        >
                            Temos diversas parcerias com hotéis e pousadas,
                            tanto no interior, quanto no litoral do Estado, para
                            você aproveitar seu merecido descanso. Venha
                            conhecer nosso departamento de Benefícios
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={5.5} marginTop={4}>
                        <Box
                            width={48}
                            height={48}
                            backgroundColor="white"
                            borderRadius={2}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SindicateCourses />
                        </Box>
                        <Typography
                            color="#FFC700"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            marginTop={2}
                        >
                            Cursos de formação e qualificação
                        </Typography>
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="130%"
                            marginTop={2}
                        >
                            O departamento de formação realiza uma série de
                            cursos que além de contribuir para o crescimento e
                            entendimento pessoal, conta na progressão de
                            carreira. Agora temos Escola de Formação em EaD para
                            você estudar de sua casa.
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={5.5} marginTop={4}>
                        <Box
                            width={48}
                            height={48}
                            backgroundColor="white"
                            borderRadius={2}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SindicateBenefits />
                        </Box>
                        <Typography
                            color="#FFC700"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="600"
                            fontSize="16px"
                            lineHeight="20px"
                            marginTop={2}
                        >
                            Outros Benefícios
                        </Typography>
                        <Typography
                            color="#FFFFFF"
                            fontFamily="Raleway"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="130%"
                            marginTop={2}
                        >
                            Além das Colônias de Férias, o associado do Sindsep
                            possui uma série de descontos em outros parceiros.
                            Academia, cinemas, teatro, clínicas médica e
                            estéticas, até mesmo faculdades e psicólogos. Entre
                            em nosso site e confira.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box
                style={{
                    display: "flex",
                }}
            >
                <Box>
                    <Typography
                        color="#EB2227"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="14px"
                        lineHeight="22px"
                        width={"40vw"}
                        paddingLeft={16}
                        paddingTop={16}
                    >
                        SOBRE O SINDSEP
                    </Typography>
                    <Typography
                        color="#010101"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="40px"
                        lineHeight="47px"
                        width={"40vw"}
                        paddingLeft={16}
                        marginTop={4}
                    >
                        Um Sindicato de luta
                    </Typography>
                    <Typography
                        color="#202020"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="20.8px"
                        width={"35vw"}
                        marginTop={4}
                        paddingLeft={16}
                        paddingBottom={16}
                    >
                        Defender os direitos dos servidores públicos municipais
                        e dos serviços públicos na cidade de São Paulo.
                        <br />
                        <br />
                        Com a retomada da democracia, as associações de
                        trabalhadores municipais se reuniram. Em 4 de julho de
                        1987 no primeiro congresso dos funcionários públicos
                        municipais de São Paulo foi criado o Sindsep | Sindicato
                        dos Trabalhadores na Administração Pública e Autarquias
                        do Município de São Paulo.
                        <br />
                        <br />
                        Independente de qual prefeito, o Sindsep se manteve
                        incansável na defesa dos trabalhadores da cidade. O
                        Sindsep está desde então na linha de frente das pautas e
                        lutas do funcionalismo público da cidade.
                    </Typography>
                </Box>

                <Box padding={10}>
                    <img
                        src={"../files/sindicato.png"}
                        width={"100%"}
                        alt="imagem do sindicato"
                    />
                </Box>
            </Box>
            <Box
                backgroundColor="white"
                borderRadius={2}
                bottom={64}
                right={64}
                position={"fixed"}
                padding={4}
            >
                <Typography
                    color="#202020"
                    fontFamily="Raleway"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="24px"
                    lineHeight="28.18px"
                    padding={4}
                >
                    Seja sócio e participe
                </Typography>
                <Box>
                    <TextField
                        id="outlined-basic"
                        label={"RF (Registro Funcional)"}
                        variant="outlined"
                        type="text"
                        name="busca"
                        aria-label="busca"
                        fullWidth
                        defaultValue={""}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        id="outlined-basic"
                        label={"CPF"}
                        variant="outlined"
                        name="busca"
                        type="number"
                        aria-label="busca"
                        fullWidth
                        defaultValue={""}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Nome/Nome Social"}
                        variant="outlined"
                        name="busca"
                        aria-label="busca"
                        type="text"
                        fullWidth
                        defaultValue={""}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        id="outlined-basic"
                        label={"E-mail"}
                        variant="outlined"
                        name="busca"
                        aria-label="busca"
                        fullWidth
                        type="email"
                        defaultValue={""}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Celular"}
                        variant="outlined"
                        name="busca"
                        aria-label="busca"
                        fullWidth
                        type="tel"
                        defaultValue={""}
                    />
                </Box>
                <Box mt={2}>
                    <TextField
                        id="outlined-basic"
                        label={"Crie uma senha"}
                        variant="outlined"
                        name="busca"
                        aria-label="busca"
                        fullWidth
                        type="password"
                        defaultValue={""}
                    />
                </Box>
                <Button
                    fullWidth
                    style={{
                        borderRadius: 32,
                        marginTop: 32,
                        textAlign: "center",
                        backgroundColor: "#EB2227",
                    }}
                >
                    <Typography
                        color="#FFFFFF"
                        fontFamily="Raleway"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="20px"
                        padding={2}
                    >
                        INICIAR CADASTRO
                    </Typography>
                </Button>
            </Box>
            <Footer />
            <SiteMap  />
        </div>
    );
};

export default Sindicate;
